import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Widget } from "./Widget";
import VideoLibraryIcon from "assets/icons/video-library.svg?react";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import { useClaimCheck } from "hooks/useClaimCheck";
import { CloudRecordingUsageResponse } from "@switcherstudio/switcher-api-client";
import { useHasPlanRole } from "hooks/useHasPlanRole";

export interface VideoLibraryWidgetProps {
    loading: boolean;
    cloudUsage: CloudRecordingUsageResponse;
}

export const VideoLibraryWidget = ({
    loading,
    cloudUsage
}: VideoLibraryWidgetProps) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const isStudioUser = useHasPlanRole("Studio");
    const isGrowUser = useHasPlanRole("Grow");

    const cloudUsagePercentageAsDecimal =
        cloudUsage?.TotalRecordings / cloudUsage?.MaxRecordings;

    const variant = useMemo(() => {
        if (cloudUsagePercentageAsDecimal >= 1) {
            return "thin-video-storage-max";
        }
        if (cloudUsagePercentageAsDecimal >= 0.8) {
            return "thin-video-storage-medium";
        }
        return "thin-video-storage";
    }, [cloudUsagePercentageAsDecimal]);

    /** If Studio user is approaching or at storage limit,
     * show Upgrade Plan button with orange color
     * and link to billing page to upgrade (instead of library, to upload) */
    const button = useMemo(() => {
        return cloudUsagePercentageAsDecimal < 0.8
            ? {
                  text: t("widgets:upload-video"),
                  styles: "btn btn-outline-secondary",
                  link: "/video-library"
              }
            : {
                  text:
                      isStudioUser || isGrowUser
                          ? t("buttons:upgrade-plan")
                          : t("widgets:manage-library"),
                  styles: "btn btn-primary",
                  link:
                      isStudioUser || isGrowUser
                          ? "/subscription#openModal"
                          : "/video-library"
              };
    }, [cloudUsagePercentageAsDecimal, isStudioUser, t, isGrowUser]);

    const footerText = useMemo(() => {
        if (cloudUsagePercentageAsDecimal >= 1) {
            return t("widgets:limit-reached");
        }
        if (cloudUsagePercentageAsDecimal >= 0.8) {
            return t("widgets:approaching-limit");
        }
        if (hasCatalogClaim) {
            return t("widgets:upload-catalog");
        }

        if ((cloudUsage?.TotalRecordings ?? 0) > 0) {
            return t("widget:upload-get-started");
        }

        return t("widgets:upload-CTA");
    }, [hasCatalogClaim, t, cloudUsagePercentageAsDecimal, cloudUsage]);

    return (
        <Widget
            loading={loading}
            icon={<VideoLibraryIcon />}
            title={t("widgets:video-library-title").toUpperCase()}
            details={[
                {
                    title: t("widgets:videos-uploaded"),
                    children: (
                        <ProgressBar
                            currentValue={cloudUsage?.TotalRecordings}
                            maxValue={cloudUsage?.MaxRecordings}
                            variant={variant}
                        />
                    )
                }
            ]}
            footerSubtitle={footerText}
            button={
                <Link className={button.styles} to={button.link}>
                    {button.text}
                </Link>
            }
        ></Widget>
    );
};
