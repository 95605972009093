import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Modals, ModalPayload, ModalsState } from "./types";

const initialState: ModalsState = {
    activeModal: { id: Modals.None, type: Modals.None },
    previousModal: { id: Modals.None, type: Modals.None },
    minimizedModals: []
};

export const modals = createSlice({
    name: "modals",
    initialState: initialState,
    reducers: {
        setActiveModal: (
            state,
            { payload }: PayloadAction<Modals | ModalPayload>
        ) => {
            const prevActiveModal = state.activeModal;

            const isModalPayload = (
                payload: Modals | ModalPayload
            ): payload is ModalPayload => {
                return (payload as ModalPayload)?.type !== undefined;
            };

            return {
                ...state,
                activeModal: isModalPayload(payload)
                    ? payload
                    : { id: payload, type: payload },
                previousModal: prevActiveModal
            };
        },
        returnToLastModal: (state) => {
            const prevActiveModal = state.activeModal;
            return {
                ...state,
                activeModal: state.previousModal,
                previousModal: prevActiveModal
            };
        },
        minimizeModal: (state) => {
            const prevActiveModal = state.activeModal;

            return {
                ...state,
                minimizedModals: [...state.minimizedModals, prevActiveModal],
                activeModal: { id: Modals.None, type: Modals.None }
            };
        },
        expandModal: (
            state,
            { payload }: PayloadAction<{ id: string | Modals }>
        ) => {
            const prevActiveModal = state.activeModal;
            // if there is a modal currently open, minimize it while expanding new active modal
            const targetModal = state.minimizedModals.find(
                (m) => m.id === payload.id
            );

            const minimizedModalsWithoutCurrentModal =
                state.minimizedModals.filter((m) => m.id !== payload.id);

            const minimizedModals =
                prevActiveModal?.id === Modals.None
                    ? minimizedModalsWithoutCurrentModal
                    : [...minimizedModalsWithoutCurrentModal, prevActiveModal];

            return {
                ...state,
                minimizedModals,
                activeModal: targetModal,
                previousModal: prevActiveModal
            };
        },
        /** Can be used for active modals and minimized modals */
        destroyModal: (
            state,
            { payload }: PayloadAction<{ id: string | Modals }>
        ) => {
            const prevActiveModal = state.activeModal ?? {
                id: Modals.None,
                type: Modals.None
            };

            return {
                ...state,
                activeModal:
                    prevActiveModal?.id === payload?.id
                        ? { id: Modals.None, type: Modals.None }
                        : state.activeModal,
                previousModal: prevActiveModal,
                minimizedModals: state.minimizedModals.filter(
                    (m) => m?.id !== payload.id
                )
            };
        },
        /** Only used for closing current active modal */
        closeCurrentModal: (state) => {
            return {
                ...state,
                activeModal: { id: Modals.None, type: Modals.None }
            };
        },
        resetModals: () => {
            return initialState;
        }
    }
});

export const {
    setActiveModal,
    minimizeModal,
    destroyModal,
    expandModal,
    returnToLastModal,
    closeCurrentModal,
    resetModals
} = modals.actions;

export default modals.reducer;
