import React from "react";
import { StripeInvoiceBindingModel } from "@switcherstudio/switcher-api-client";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface InvoiceTableProps {
    invoices: StripeInvoiceBindingModel[];
    dateRangeSelectorComponent: JSX.Element;
    displayDateFn: (date?: string) => string | undefined;
    displayCostFn: (amount?: number) => string | undefined;
}

function invoiceIsCredit(invoice: StripeInvoiceBindingModel) {
    const displayedPrice =
        invoice.applied_balance < 0 ? invoice.amount_due : invoice.total;

    return displayedPrice >= 0 ? false : true;
}

export const InvoiceTableV2: React.FC<InvoiceTableProps> = ({
    invoices,
    dateRangeSelectorComponent,
    displayDateFn,
    displayCostFn
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles["invoice-table"]}>
            {invoices && (
                <>
                    {!invoices || invoices.length === 0 ? (
                        <div className="alert alert-info" role="alert">
                            {t("messages:no-invoices")}
                        </div>
                    ) : null}
                    <header className={styles["invoice-header"]}>
                        <h4>{t("subscription:invoice-history")}</h4>
                        {dateRangeSelectorComponent}
                    </header>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("subscription:invoice-date")}</th>
                                <th>{t("subscription:total")}</th>
                                <th>{t("subscription:status")}</th>
                                <th>{t("misc:action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices?.map((invoice, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{displayDateFn(invoice.date)}</td>
                                        <td>
                                            {displayCostFn(
                                                invoice.applied_balance < 0
                                                    ? invoice.amount_due
                                                    : invoice.total
                                            )}
                                        </td>
                                        <td>
                                            {invoiceIsCredit(invoice)
                                                ? t("subscription:credit")
                                                : t("subscription:paid")}
                                        </td>
                                        <td>
                                            {invoice.paid && (
                                                <a
                                                    className={`${styles["table-action"]}`}
                                                    href={
                                                        invoice.hosted_invoice_url
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t("messages:view")}
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};
