import React, { useMemo, useCallback } from "react";
import styles from "../GenericMultiSelectModal/index.module.scss";
import {
    CreatorProduct,
    CreatorProductPrice
} from "@switcherstudio/switcher-api-client";
import { displayAmount } from "helpers/stripe";
import { useTranslation } from "react-i18next";

interface PricingDetailsProps {
    item: CreatorProduct;
}

export const PricingDetails = ({ item }: PricingDetailsProps) => {
    const { t } = useTranslation();

    const oneTimePrice = useMemo<CreatorProductPrice>(
        () => item?.Prices.find((p) => !p?.IsRecurring),
        [item]
    );

    const monthlyPrice = useMemo<CreatorProductPrice>(
        () =>
            item?.Prices.find(
                (p) => p?.IsRecurring && p?.RecurringInterval === "month"
            ),
        [item]
    );

    const yearlyPrice = useMemo<CreatorProductPrice>(
        () =>
            item?.Prices.find(
                (p) => p?.IsRecurring && p?.RecurringInterval === "year"
            ),
        [item]
    );

    const price = useMemo<CreatorProductPrice>(
        () => oneTimePrice ?? monthlyPrice ?? yearlyPrice,
        [monthlyPrice, oneTimePrice, yearlyPrice]
    );

    const _displayAmount = useCallback(
        (amount: number) =>
            displayAmount(amount, {
                signed: true,
                compact: false,
                roundUp: false
            }),
        []
    );

    const oneTimeRentalText = useMemo(() => {
        if (oneTimePrice && oneTimePrice?.EntitledDuration > 0) {
            return `${oneTimePrice.EntitledDuration} ${t(
                `gated-content-page:timed-access-units:${
                    oneTimePrice.EntitledDuration === 1
                        ? {
                              hours: "hour",
                              days: "day",
                              weeks: "week"
                          }[oneTimePrice.EntitledDurationUnit.toLowerCase()]
                        : oneTimePrice.EntitledDurationUnit.toLowerCase()
                }`
            )}`;
        }
    }, [oneTimePrice, t]);

    return (
        <>
            {!!item && (
                <div className="row w-100">
                    <div className="col-lg-6">
                        <div className={styles["details-container"]}>
                            <div className={styles["details"]}>
                                <h6>{item.Name}</h6>
                                <div className={styles["time-row"]}>
                                    {price?.IsRecurring ? (
                                        <span>
                                            {monthlyPrice && (
                                                <div>{`${_displayAmount(
                                                    monthlyPrice.Amount
                                                )} / ${t(
                                                    "gated-content-page:month"
                                                )}`}</div>
                                            )}
                                            {yearlyPrice && (
                                                <div>{`${_displayAmount(
                                                    yearlyPrice.Amount
                                                )} / ${t(
                                                    "gated-content-page:year"
                                                )}`}</div>
                                            )}
                                        </span>
                                    ) : oneTimePrice &&
                                      oneTimePrice.EntitledDuration > 0 ? (
                                        <div>{`${_displayAmount(
                                            oneTimePrice.Amount
                                        )} / ${oneTimeRentalText}`}</div>
                                    ) : (
                                        _displayAmount(price?.Amount)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
