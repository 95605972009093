export const getResultsFromPromiseAllSettled = <T>(
    results: PromiseSettledResult<T>[]
) => {
    return results.reduce(
        (memo, currentResult) => {
            return {
                fulfilled:
                    currentResult.status === "fulfilled"
                        ? [...memo.fulfilled, currentResult]
                        : memo.fulfilled,
                rejected:
                    currentResult.status === "rejected"
                        ? [...memo.rejected, currentResult]
                        : memo.rejected
            };
        },
        {
            fulfilled: [] as PromiseFulfilledResult<T>[],
            rejected: [] as PromiseRejectedResult[]
        }
    );
};

/**
 * Moves an item in an array and returns a modified clone of the array with new order
 * @param array Array to rearrange
 * @param from index of item to move
 * @param to index of new position
 * @returns modified clone of original array
 */
export const move = <T>(array: T[], from: number, to: number) => {
    const clone = [...array];
    clone.splice(to, 0, clone.splice(from, 1)[0]);
    return clone;
};

/**
 * Retrieves the first item from an array.
 * @param array The array to retrieve the first item from.
 * @returns The first item of the array, or undefined if the array is empty.
 */
export const first = <T, U = null>(array: T[], fallback: U = null): T | U => {
    return array?.length > 0 ? array[0] : fallback;
};

/**
 * Retrieves the last item from an array.
 * @param array The array to retrieve the last item from.
 * @returns The last item of the array, or undefined if the array is empty.
 */
export const last = <T extends object, U = null>(
    array: T[],
    fallback: U = null
): T | U => {
    return array?.length > 0 ? array[array.length - 1] : fallback;
};
