import store from "store/store";
import { YtOptions } from "./types";
import { setLoading } from "store/loading/slice";

const baseUrl = `${
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net"
}/api/streamingproviders/youtube/proxy/`; // TODO: config var for url

export default async function ytFetch(options: YtOptions) {
    const state = store.getState();
    const url = `${baseUrl}${options.resource}?${parseFields(options)}`;
    const requestBody = (options.shouldSerializeBody ?? true)
        ? (JSON.stringify(options.body) || null)
        : options.body;
    store.dispatch(setLoading(1));

    try {
        const response = await fetch(url, {
            method: options.method || "GET",
            body: requestBody,
            headers: {
                ...(options.headers || {}),
                Authorization: `bearer ${state.user.ticket!.access_token}`
            }
        });
        const body = await handleResponse(response);

        if (response.status >= 400) {
            throw new Error(body?.error?.message);
        }

        return body;
    } catch (e) {
        throw e;
    } finally {
        store.dispatch(setLoading(-1));
    }
}

async function handleResponse(response) {
    const text = await response.text();

    try {
        let result = null;
        result = text === "" ? null : JSON.parse(text);

        return result;
    } catch (e) {
        throw new Error("Invalid JSON");
    }
}

function parseFields(options) {
    let fieldsArray: string[] = [];
    for (let i in options.fields) {
        // if field has value add it to array
        if (options.fields[i]) {
            fieldsArray.push(`${i}=${encodeURIComponent(options.fields[i])}`);
        }
    }
    return fieldsArray.join("&");
}
