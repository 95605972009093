import GatedContentCards from "components/radio-cards/gated-content-cards";
import { ActiveSubscribersTable } from "components/modal/GatedContentModal/ActiveSubscribersTable";
import styles from "./index.module.scss";

export const CatalogPurchaseOptions = ({
    formValues,
    priceErrors,
    handlePriceChange,
    createOrUpdate,
    entitlementAndPrices,
    oneTimePassFeatureEnabled,
    untimedOTPInitiallySelected
}) => {
    return (
        <>
            <div className={styles["pass-container"]}>
                <GatedContentCards
                    formValues={formValues}
                    errors={priceErrors}
                    type={createOrUpdate}
                    variant="catalog"
                    onPassChange={handlePriceChange}
                    creatorProductId={
                        entitlementAndPrices?.ProductEntitlements?.[0]
                            ?.ProductId
                    }
                    shouldShowOneTimePassOption={oneTimePassFeatureEnabled}
                    untimedOTPInitiallySelected={untimedOTPInitiallySelected}
                />
            </div>

            <ActiveSubscribersTable
                creatorProduct={
                    entitlementAndPrices?.ProductEntitlements?.[0]?.Product
                }
            />
        </>
    );
};
