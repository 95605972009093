import {
    CreatorProduct,
    CreatorProductPrice,
    CreatorProductPriceEntitledDurationUnit,
    CreatorProductPricesBindingModel,
    CreatorProductPricesBindingModelEntitledDurationUnit
} from "@switcherstudio/switcher-api-client";
import { v4 as uuidv4 } from "uuid";

export const buildPricesArray = ({
    recurringMonthlyPriceSelected,
    recurringAnnualPriceSelected,
    recurringMonthlyPrice,
    recurringAnnualPrice,
    oneTimePrice,
    oneTimeDurationSelected,
    oneTimeDuration,
    oneTimeDurationUnits,
    oldProduct,
    oldRecurringMonthlyPrice,
    oldRecurringAnnualPrice,
    oldOneTimePrice,
    name,
    selectedPassType
}: {
    recurringMonthlyPriceSelected: boolean;
    recurringAnnualPriceSelected: boolean;
    recurringMonthlyPrice: string;
    recurringAnnualPrice: string;
    oneTimePrice: string;
    oneTimeDurationSelected: boolean;
    oneTimeDuration: string;
    oneTimeDurationUnits: CreatorProductPriceEntitledDurationUnit | undefined;
    oldProduct: CreatorProduct;
    oldRecurringMonthlyPrice: CreatorProductPrice;
    oldRecurringAnnualPrice: CreatorProductPrice;
    oldOneTimePrice: CreatorProductPrice;
    name: string;
    selectedPassType: "one-time" | "recurring";
}): CreatorProductPricesBindingModel[] => {
    const pricesArray: CreatorProductPricesBindingModel[] = [];

    if (selectedPassType === "recurring") {
        if (oldOneTimePrice) {
            // Soft delete old one-time price if recurring is selected
            pricesArray.push({
                ...oldOneTimePrice,
                IsActive: false,
                IsPublic: false,
                EntitledDurationUnit: !oldOneTimePrice?.EntitledDurationUnit
                    ? null
                    : CreatorProductPricesBindingModelEntitledDurationUnit[
                          oldOneTimePrice.EntitledDurationUnit || "Hours"
                      ]
            } as CreatorProductPricesBindingModel);
        }
    } else if (selectedPassType === "one-time") {
        if (oldOneTimePrice) {
            const newDuration = oneTimeDurationSelected
                ? oneTimeDuration
                : null;
            const newDurationUnits = oneTimeDurationSelected
                ? oneTimeDurationUnits || "Hours"
                : null;
            const updateObject = {
                ...oldOneTimePrice,
                Name: name,
                Amount: parseFloat(oneTimePrice) * 100,
                ...(newDuration && {
                    EntitledDuration: parseFloat(newDuration)
                }),
                ...(!newDuration && {
                    EntitledDuration: null,
                    EntitledDurationUnit: null,
                    EntitledDurationSeconds: null
                }),
                ...(newDurationUnits && {
                    EntitledDurationUnit:
                        CreatorProductPricesBindingModelEntitledDurationUnit[
                            newDurationUnits
                        ]
                })
            } as CreatorProductPricesBindingModel;
            pricesArray.push(updateObject);
        } else {
            const duration = oneTimeDurationSelected ? oneTimeDuration : null;
            const durationUnits = oneTimeDurationSelected
                ? oneTimeDurationUnits || "Hours"
                : null;
            const createObject = {
                Id: uuidv4(),
                Name: name,
                IsActive: true,
                IsPublic: true,
                ProductId: oldProduct?.Id,
                Amount: parseFloat(oneTimePrice) * 100,
                BillingScheme: "per_unit",
                ...(duration && { EntitledDuration: parseFloat(duration) }),
                ...(durationUnits && {
                    EntitledDurationUnit:
                        CreatorProductPricesBindingModelEntitledDurationUnit[
                            durationUnits
                        ]
                })
            };
            pricesArray.push(createObject);
        }
    } else if (!selectedPassType) {
        if (oldOneTimePrice) {
            pricesArray.push({
                ...oldOneTimePrice,
                IsActive: false,
                IsPublic: false,
                EntitledDurationUnit: !oldOneTimePrice?.EntitledDurationUnit
                    ? null
                    : CreatorProductPricesBindingModelEntitledDurationUnit[
                          oldOneTimePrice?.EntitledDurationUnit || "Hours"
                      ]
            } as CreatorProductPricesBindingModel);
        }
    }

    // build and push recurring monthly price object
    if (recurringMonthlyPriceSelected) {
        if (
            oldRecurringMonthlyPrice &&
            parseFloat(recurringMonthlyPrice) !==
                oldRecurringMonthlyPrice.Amount
        ) {
            const updateObject = {
                ...oldRecurringMonthlyPrice,
                Name: name,
                Amount: parseFloat(recurringMonthlyPrice) * 100,
                EntitledDuration: null,
                EntitledDurationUnit: null,
                EntitledDurationSeconds: null
            } as CreatorProductPricesBindingModel;
            pricesArray.push(updateObject);
        } else if (!oldRecurringMonthlyPrice) {
            const createObject = {
                Id: uuidv4(),
                Name: name,
                IsActive: true,
                IsPublic: true,
                ProductId: oldProduct?.Id,
                Amount: parseFloat(recurringMonthlyPrice) * 100,
                BillingScheme: "per_unit",
                RecurringUsageType: "licensed",
                IsRecurring: true,
                RecurringInterval: "month",
                RecurringIntervalCount: 1
            };
            pricesArray.push(createObject);
        }
    } else if (selectedPassType === "one-time" || oldRecurringMonthlyPrice) {
        if (oldRecurringMonthlyPrice) {
            pricesArray.push({
                ...oldRecurringMonthlyPrice,
                IsActive: false,
                IsPublic: false,
                EntitledDurationUnit:
                    !oldRecurringMonthlyPrice?.EntitledDurationUnit
                        ? null
                        : CreatorProductPricesBindingModelEntitledDurationUnit[
                              oldRecurringMonthlyPrice.EntitledDurationUnit ||
                                  "Hours"
                          ]
            } as CreatorProductPricesBindingModel);
        }
    }

    // build and push recurring annual price object
    if (recurringAnnualPriceSelected) {
        if (
            oldRecurringAnnualPrice &&
            parseFloat(recurringAnnualPrice) !== oldRecurringAnnualPrice.Amount
        ) {
            const updateObject = {
                ...oldRecurringAnnualPrice,
                Name: name,
                Amount: parseFloat(recurringAnnualPrice) * 100,
                EntitledDuration: null,
                EntitledDurationUnit: null,
                EntitledDurationSeconds: null
            } as CreatorProductPricesBindingModel;
            pricesArray.push(updateObject);
        } else if (!oldRecurringAnnualPrice) {
            const createObject = {
                Id: uuidv4(),
                Name: name,
                IsActive: true,
                IsPublic: true,
                ProductId: oldProduct?.Id,
                Amount: parseFloat(recurringAnnualPrice) * 100,
                BillingScheme: "per_unit",
                RecurringUsageType: "licensed",
                RecurringInterval: "year",
                RecurringIntervalCount: 1
            };
            pricesArray.push(createObject);
        }
    } else if (selectedPassType === "one-time" || oldRecurringAnnualPrice) {
        if (oldRecurringAnnualPrice) {
            pricesArray.push({
                ...oldRecurringAnnualPrice,
                IsActive: false,
                IsPublic: false,
                EntitledDurationUnit:
                    !oldRecurringAnnualPrice?.EntitledDurationUnit
                        ? null
                        : CreatorProductPricesBindingModelEntitledDurationUnit[
                              oldRecurringAnnualPrice.EntitledDurationUnit ||
                                  "Hours"
                          ]
            } as CreatorProductPricesBindingModel);
        }
    }

    return pricesArray;
};
