import { useEffect, useMemo, useState } from "react";
import { useSwitcherClient } from "./useSwitcherClient";
import { useDateRangeSelector } from "./useDateRangeSelector";
import { useTranslation } from "react-i18next";

enum SubscriptionDateRangeOptions {
    ThirtyDays = "thirtyDays",
    MonthToDate = "monthToDate",
    YearToDate = "yearToDate",
    TwelveMonths = "twelveMonths",
    Custom = "custom"
}

export interface UseUserInvoicesOptions {
    start?: string;
    end?: string;
}

export const useUserInvoices = ({
    start = undefined,
    end = undefined
}: UseUserInvoicesOptions) => {
    const { t, i18n } = useTranslation();

    const [refetchCounter, setRefetchInvoicesCounter] = useState<number>(0);

    const [selectedTimeFrame, setSelectedTimeFrame] =
        useState<SubscriptionDateRangeOptions>(
            SubscriptionDateRangeOptions.TwelveMonths
        );

    const { initialStart, initialEnd } = useMemo(() => {
        const now = () => new Date();
        return {
            initialStart: new Date(
                start ?? now().setFullYear(now().getFullYear() - 1)
            ),
            initialEnd: end ? new Date(end) : now()
        };
    }, [end, start]);

    const [customStartDate, setCustomStartDate] = useState<Date | null>(
        initialStart
    );
    const [customEndDate, setCustomEndDate] = useState<Date | null>(initialEnd);

    const {
        start: _start,
        end: _end,
        component: InvoiceDateRangeSelectorComponent
    } = useDateRangeSelector({
        id: "invoice-date-range",
        label: t("subscription:date-range-label"),
        selection:
            selectedTimeFrame ?? SubscriptionDateRangeOptions.TwelveMonths,
        options: Object.values(SubscriptionDateRangeOptions).map((value) => ({
            value,
            text: t(`date-range-options:${value}`)
        })),
        onChange: (e) => {
            setSelectedTimeFrame(
                e.target.value as SubscriptionDateRangeOptions
            );
        },

        customStartDate: customStartDate,
        onStartDateChange: (date) => setCustomStartDate(date),
        placeholderStartDate: initialStart,
        customEndDate: customEndDate,
        onEndDateChange: (date) => setCustomEndDate(date),
        placeholderEndDate: initialEnd,
        datePickerProps: {
            showTimeSelect: false,
            locale: i18n.language
        }
    });

    const { data: invoices, dispatchApiRequest: refetchInvoices } =
        useSwitcherClient((client) => client.stripe_GetInvoices, {
            requestImmediately: true,
            args: [_start, _end]
        });

    useEffect(() => {
        // Trigger a refetch of the invoices when the refetchCounter changes (in parent)
        if (refetchCounter > 0) refetchInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchCounter]);

    const paidInvoices = useMemo(
        () =>
            invoices?.filter(
                (invoice) => invoice.paid && invoice.status === "paid"
            ) ?? undefined,
        [invoices]
    );

    const draftInvoices = useMemo(
        () =>
            invoices?.filter((invoice) => invoice.status === "draft") ??
            undefined,
        [invoices]
    );

    const upcomingDraftInvoice = useMemo(() => {
        if (!draftInvoices) return undefined;
        return draftInvoices.find(
            (invoice) =>
                invoice.next_payment_attempt &&
                new Date(invoice.next_payment_attempt) > new Date()
        );
    }, [draftInvoices]);

    return {
        invoices,
        paidInvoices,
        draftInvoices,
        upcomingDraftInvoice,
        InvoiceDateRangeSelectorComponent,
        refresh: () => setRefetchInvoicesCounter((prev) => prev + 1)
    };
};
