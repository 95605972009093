import {
    loadStripe,
    StripeElementLocale,
    StripeElementsOptions
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { PropsWithChildren } from "react";
import i18n from "localization/i18n";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY);
const defaultOptions: StripeElementsOptions = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap"
        }
    ],

    locale: i18n.language as StripeElementLocale,

    appearance: {
        theme: "stripe",

        variables: {
            fontFamily: "Montserrat",
            colorPrimary: "#1ebdc1",
            colorText: "#515251",
            fontSizeBase: "1rem",
            fontSizeSm: ".5rem",
            spacingUnit: "3px",
            spacingTab: ".28125rem",
            spacingGridRow: ".5rem"
        },

        labels: "floating",

        rules: {
            ".Tab": {
                border: "2px solid #cccccc",
                borderRadius: "8px",
                backgroundColor: "#f2f2f3"
            },

            ".Tab:hover": {
                color: "var(--colorText)",
                backgroundColor: "#eee",
                filter: "brightness(90%)"
            },

            ".Tab--selected": {
                color: "var(--colorTextSecondary)",
                backgroundColor: "#1ebdc11a",
                border: "2px solid var(--colorPrimary)"
            },

            ".Tab--selected:hover": {
                color: "var(--colorTextSecondary)",
                backgroundColor: "#1ebdc11a",
                borderColor: "var(--colorPrimary)"
            },

            ".Tab--selected:focus": {
                color: "var(--colorTextSecondary)",
                borderColor: "var(--colorPrimary)"
            },

            ".Input": {
                border: "2px solid #ccc",
                marginTop: "0.5625rem"
            },

            ".Input:focus": {
                border: "none",
                outline: "2px solid var(--colorPrimary)"
            },

            ".Label--floating": {
                fontSize: "var(--fontSizeSm)",
                color: "var(--colorPrimary)"
            }
        }
    }
};

export interface StripeElementProps extends PropsWithChildren {
    options?: StripeElementsOptions;
}

export const StripeElement: React.FC<StripeElementProps> = ({
    children,
    options
}) => {
    return (
        <Elements
            stripe={stripePromise}
            options={{ ...defaultOptions, ...options } as StripeElementsOptions}
        >
            {children}
        </Elements>
    );
};
