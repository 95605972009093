import { PayloadAction, Reducer, createSlice } from "@reduxjs/toolkit";
import { UploadProps, UploadsState } from "./types";

export const uploads = createSlice({
    name: "uploads",
    initialState: {
        processingOrQueued: [],
        ready: []
    } as UploadsState,
    reducers: {
        /** Add the broadcast id to the processing or queued array. */
        addToProcessingOrQueued: (
            state,
            { payload }: PayloadAction<UploadProps>
        ) => {
            const isInProcessingOrQueued = state.processingOrQueued.some(
                (ul) => ul?.broadcastId === payload?.broadcastId
            );
            if (!isInProcessingOrQueued) {
                state.processingOrQueued = [
                    ...state.processingOrQueued,
                    payload
                ];
            }
        },
        /** Move the broadcast id to the ready array from the processing or queued array. */
        moveToReady: (state, { payload }: PayloadAction<UploadProps>) => {
            state.processingOrQueued = [
                ...state.processingOrQueued.filter(
                    (ul) => ul?.broadcastId !== payload?.broadcastId
                )
            ];
            state.ready = [...state.ready, payload];
        },
        /** Cancel all uploads. */
        cancelUploads: (state) => {
            state.processingOrQueued = [];
            state.ready = [];
        }
    }
});

export const { addToProcessingOrQueued, moveToReady, cancelUploads } =
    uploads.actions;

export default uploads.reducer as Reducer<UploadsState>;
