import React, { useEffect, useState } from "react";
import styles from "./PlatformFormPage.module.scss";
import { client } from "api/client";
import {
    Broadcast,
    StreamingProvider
} from "@switcherstudio/switcher-api-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { PlatformId } from "../../types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    createFacebookStream,
    updateFacebookStream,
    createYouTubeEvent,
    updateTwitchChannel,
    updateYoutubeEvent,
    updateVideoPlayerStream
} from "store/platforms/thunks";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformForm } from "./PlatformForm";
import { PlatformInitialState } from "views/page-content/platforms/types";
import { ScheduledOptions } from "./types";
import { updateFormState } from "store/platforms/slice";
import { compareMinutes } from "helpers/time";
import { switcherSdk } from "utils/switcher-sdk";
import rollbar from "helpers/rollbar";
import { trackEvent } from "helpers/analyticsHelpers";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useParams } from "hooks/useParams";

export const PlatformFormPage: React.FC = () => {
    const { platformId, destinationType, destinationId } = useParams();

    const { t, i18n } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();

    const [platform, setPlatform] = useState<StreamingProvider>();
    const { general, videoplayer } = useSelector(
        (state: RootState) => state.platforms
    );
    const { formState } = general;
    const { userInfo } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const { init } = usePlatformActions(platformId as PlatformId);
    const [initialState, setInitialState] = useState<PlatformInitialState>(
        {} as PlatformInitialState
    );
    const { isInBrowser } = useSwitcherSdk();

    useEffect(() => {
        async function run() {
            const response =
                await client.streamingProviders_GetStreamingProvider(
                    platformId
                );
            if (isMountedRef.current) {
                setPlatform(response);
            }
        }

        if (isMountedRef.current) {
            if (!platform) {
                run();
            } else if (!platform.IsLinked) {
                navigate(`/platforms/connect/${platformId}`, {
                    replace: true
                });
                return;
            }
        }
    }, [isMountedRef, navigate, platform, platformId]);

    useEffect(() => {
        const _init = async () => {
            try {
                const state = await init({ destinationType, destinationId });
                if (isMountedRef.current) {
                    setInitialState(state);
                }
            } catch (e) {
                rollbar.error("Error initializing platform", e, {
                    platformId: platformId
                });

                const msg = e.message.toLowerCase().replaceAll(" ", "-");
                const errorMsg = i18n.exists("errors:" + msg)
                    ? "errors:" + msg
                    : "errors:platform-load-error";

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: errorMsg,
                        messageOptions: { platform: platformId },
                        clickText:
                            platformId === PlatformId.Youtube
                                ? "messages:click-help"
                                : null,
                        clickAction:
                            platformId === PlatformId.Youtube
                                ? () => {
                                      if (switcherSdk.clientId === "Generic") {
                                          window.open(
                                              "https://support.switcherstudio.com/en/articles/3065584-streaming-with-a-youtube-account",
                                              "_blank",
                                              "noopener"
                                          );
                                      } else {
                                          switcherSdk.openIsolatedWebView(
                                              "https://support.switcherstudio.com/en/articles/3065584-streaming-with-a-youtube-account",
                                              null,
                                              null,
                                              () => {}
                                          );
                                      }
                                  }
                                : null
                    })
                );
                navigate(`/platforms/${platformId}`);
            }
        };
        // always reset form state when we first arrive.
        dispatch(updateFormState(ScheduledOptions.Now));
        // init gets the initial state of the component which for the moment consists of
        // name - user or platform destination name,
        // imgSrc - user or platform destination image,
        // existingScheduledStreams (if any) - previously scheduled streams,
        // hasAccess - whether or not the destation is enabled for live streaminig
        _init();
    }, [
        dispatch,
        init,
        platformId,
        navigate,
        isMountedRef,
        destinationType,
        destinationId,
        i18n
    ]);

    const isTitleVisible = !!platform;

    const createBroadcast = async () => {
        const isScheduled = formState === ScheduledOptions.Later;
        const bcast: Broadcast = {
            Title: general.generalForm.title,
            Description: general.generalForm.description,
            ProjectId: userInfo?.ProjectId
        };

        if (isScheduled) {
            bcast.StartsAt =
                general.generalForm.scheduledStartTime.toISOString();
        }

        bcast.EnableLiveShopping =
            (videoplayer?.videoPlayerForm?.enableLiveShopping as boolean) ??
            false;

        const res = await client.broadcasts_PostBroadcast(bcast, false);

        trackEvent("Event Created", {
            platform: platformId,
            inDashboard: isInBrowser,
            broadcastId: res.Id,
            name: general.generalForm.title,
            scheduledForLater: isScheduled,
            scheduledTime: general.generalForm.scheduledStartTime.toISOString(),
            scheduledTimeFromNow: Math.floor(
                (general.generalForm.scheduledStartTime.getTime() -
                    new Date().getTime()) /
                    1000
            ),
            existingScheduled: general.selectedStreams[platformId] !== null,
            usedSpeedTest: general.generalForm.usedSpeedTest,
            coverImageProvided: !!general.generalForm.scheduledImageFile,
            collectionsSpecified: false
        });

        return res;
    };

    const getUserFriendlyError = (e: any, platformId: PlatformId): string => {
        let userFriendlyError = e?.message || "errors:platform-error";

        const unfriendlyErrorsAndReplacements = {
            "(#200) Permissions error": "errors:facebook-permission-error",
            "(#200) Subject does not have permission to create live video on this page":
                "errors:facebook-permission-error",
            "Permissions error": "errors:facebook-permission-error",
            "An active access token must be used to query information about the current user.":
                "errors:facebook-permission-error",
            "An unknown error has occurred.": "errors:generic-platform-error",
            "An unexpected error has occurred. Please retry your request later.":
                "errors:generic-platform-error",
            "Embed setting was invalid": "platforms:embed-restriction"
        };

        if (userFriendlyError in unfriendlyErrorsAndReplacements) {
            userFriendlyError =
                unfriendlyErrorsAndReplacements[userFriendlyError];
        }

        // Determine the platform name to display in the error message
        let prettyPlatformName: string;
        if (
            [
                PlatformId.Facebook,
                PlatformId.Youtube,
                PlatformId.Twitch
            ].includes(platformId)
        ) {
            prettyPlatformName = t(`platforms:${platformId}`);
        } else {
            prettyPlatformName = t("platforms:generic-platform");
        }

        return t(userFriendlyError, { platform: prettyPlatformName });
    };

    async function handleSubmit() {
        if (platform.IsLinked) {
            try {
                // validate common required form fields for each platform form
                if (isTitleVisible && !general.generalForm.title.trim()) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:title-error"
                        })
                    );
                    return;
                }
                switch (platformId) {
                    case PlatformId.Facebook:
                        if (
                            formState === ScheduledOptions.Later &&
                            compareMinutes(
                                general.generalForm.scheduledStartTime,
                                new Date(),
                                10
                            )
                        ) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: t(
                                        "errors:scheduled-time-custom-error",
                                        {
                                            minutes: 10
                                        }
                                    )
                                })
                            );
                        }
                        if (
                            formState === ScheduledOptions.Later &&
                            !general.generalForm.description.trim()
                        ) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:description-error"
                                })
                            );
                            return;
                        }
                        const fbBroadcast = await createBroadcast();
                        general.selectedStreams.facebook === null
                            ? await dispatch(
                                  createFacebookStream({
                                      showNotification: true,
                                      broadcastId: fbBroadcast.Id,
                                      isSimulcast: false
                                  })
                              )
                            : await dispatch(updateFacebookStream(undefined));
                        navigate("/platforms");
                        break;
                    case PlatformId.Youtube:
                        if (
                            formState === ScheduledOptions.Later &&
                            compareMinutes(
                                general.generalForm.scheduledStartTime,
                                new Date(),
                                20
                            )
                        ) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: t(
                                        "errors:scheduled-time-custom-error",
                                        {
                                            minutes: 20
                                        }
                                    )
                                })
                            );
                        }
                        const ytBroadcast = await createBroadcast();
                        general.selectedStreams.youtube === null
                            ? await dispatch(
                                  createYouTubeEvent({
                                      showNotification: true,
                                      swiBroadcastId: ytBroadcast.Id,
                                      isSimulcast: false
                                  })
                              )
                            : await dispatch(updateYoutubeEvent(undefined));
                        navigate("/platforms");
                        break;
                    case PlatformId.Twitch:
                        const twitchBroadcast = await createBroadcast();
                        await dispatch(
                            updateTwitchChannel({
                                showNotification: true,
                                broadcastId: twitchBroadcast.Id,
                                isSimulcast: false
                            })
                        );
                        navigate("/platforms");
                        break;
                    case PlatformId.VideoPlayer:
                        const videoPlayerBroadcast = await createBroadcast();
                        await dispatch(
                            updateVideoPlayerStream({
                                showNotification: true,
                                broadcast: videoPlayerBroadcast,
                                isSimulcast: false
                            })
                        );
                        navigate("/platforms");
                        break;
                    default:
                        return null;
                }
            } catch (e) {
                rollbar.error("Error submitting platform form", e, {
                    platformId
                });

                const userError = getUserFriendlyError(
                    e,
                    platformId as PlatformId
                );

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: userError
                    })
                );
            }
        }
    }

    return (
        <>
            {platform && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className={styles["header"]}>
                            {platformId === PlatformId.VideoPlayer ? (
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={() => navigate(`/platforms`)}
                                >
                                    <ChevronLeft /> {t("buttons:back")}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={() =>
                                        navigate(`/platforms/${platformId}`)
                                    }
                                >
                                    <ChevronLeft />{" "}
                                    {t("buttons:change-destination")}
                                </button>
                            )}
                        </div>
                        <PlatformForm
                            platform={platform}
                            destinationId={destinationId}
                            destinationType={destinationType}
                            isTitleVisible={isTitleVisible}
                            isDescriptionVisible={
                                platform.Id !== PlatformId.Twitch
                            }
                            isSchedulingAvailable={
                                platform.Id !== PlatformId.Twitch
                            }
                            initialState={initialState}
                        />
                        <button
                            type="button"
                            onClick={handleSubmit}
                            disabled={
                                general.generalForm.isInvalid ||
                                !initialState?.hasAccess
                            }
                            className={`btn btn-primary ${styles["save-btn"]}`}
                        >
                            {t("buttons:save")}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};
