import { VideoUploadSessionState, VideoUploadSession } from "./types";

const initialUploadSessionState: Omit<VideoUploadSession, "id"> = {
    entryPointDragAndDropInstance: null,
    modalDragAndDropInstance: null,
    files: null,
    filesAdded: false,
    onUploadComplete: null,
    uploadsStarted: false,
    allComplete: false,
    sessionHasErrors: false,
    pendingBroadcasts: {},
    totalResolvedUploads: 0,
    categories: [],
    webLinks: [],
    lockedToPlayer: "",
    onFileAdded: null
};

export const getCurrentSession = (
    state: VideoUploadSessionState,
    payload: { sessionId: string }
): VideoUploadSession => {
    return (
        state.uploadSessions[payload.sessionId] ??
        ({
            id: payload.sessionId,
            ...initialUploadSessionState
        } as VideoUploadSession)
    );
};
