import React, { useEffect } from "react";
import { RootState } from "store/reducers";
import { FormWrapper } from "./FormWrapper";
import { FormSection } from "./FormSection";
import { ConditionalScheduling } from "components/conditional-scheduling/ConditionalScheduling";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import {
    updateGeneralForm,
    updateFormState,
    updateSelectedStream
} from "store/platforms/slice";
import { NoAccess } from "../no-access/NoAccess";
import { QualitySelector } from "components/quality-selector/QualitySelector";
import { useTranslation } from "react-i18next";
import { TextArea } from "components/inputs/textarea/Textarea";
import { TextInput } from "components/inputs/text-input/TextInput";
import { GeneralPlatformForm } from "store/platforms/types";
import { PlatformFormProps, ScheduledOptions } from "./types";
import { PlatformId } from "views/page-content/platforms/types";
import dayjs from "dayjs";

export const PlatformForm: React.FC<PlatformFormProps> = ({
    platform,
    destinationId,
    destinationType,
    isTitleVisible,
    isDescriptionVisible,
    isSchedulingAvailable,
    initialState
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { general } = useSelector((state: RootState) => state.platforms);
    const { generalForm, formState } = general;
    const scheduleOptions = [
        { text: t("platforms:schedule-live"), value: ScheduledOptions.Now },
        {
            text: t("platforms:schedule-later"),
            value: ScheduledOptions.Later
        },
        ...(platform.Id !== PlatformId.VideoPlayer
            ? [
                  {
                      text: t("platforms:schedule-existing"),
                      value: ScheduledOptions.Existing
                  }
              ]
            : [])
    ];

    const updateScheduleState = (newState: ScheduledOptions) => {
        if (newState !== formState) {
            dispatch(updateFormState(newState));
        }
    };
    const onChange = (update: Partial<GeneralPlatformForm>) => {
        dispatch(updateGeneralForm(update));
    };

    useEffect(() => {
        // Effect to set/unset selected stream if any existing streams exist
        // we will be able to set the title / description here in the future when
        // there is an shared interface for 'scheduled streams' for now they are all unique
        // so that logic is in their respective form sections.
        if (
            isSchedulingAvailable &&
            formState === ScheduledOptions.Existing &&
            initialState.existingStreams?.length
        ) {
            dispatch(
                updateSelectedStream({
                    id: platform.Id,
                    stream: initialState.existingStreams[0]
                })
            );
        } else if (isSchedulingAvailable) {
            dispatch(
                updateSelectedStream({
                    id: platform.Id,
                    stream: null
                })
            );
            dispatch(
                updateGeneralForm({
                    scheduledStartTime: (formState === ScheduledOptions.Later
                        ? dayjs().add(1, "hour")
                        : dayjs()
                    ).toDate(),
                    // Scheduled Streams outside of MultiStreams are not allowed
                    isInvalid: formState === ScheduledOptions.Later
                })
            );
        } else if (formState === ScheduledOptions.Now) {
            dispatch(
                updateGeneralForm({
                    scheduledStartTime: new Date(),
                    isInvalid: false
                })
            );
        }
    }, [dispatch, formState, initialState, platform.Id, isSchedulingAvailable]);

    const getInputMaxLength = () => {
        switch (platform.Id) {
            case PlatformId.Facebook:
                return {
                    title: 254,
                    description: 10000
                };
            default:
                return {
                    title: 100,
                    description: 5000
                };
        }
    };

    return (
        initialState && (
            <FormWrapper
                destinationName={initialState?.name}
                imageUrl={initialState?.imgSrc}
            >
                {initialState?.hasAccess ? (
                    <>
                        {isSchedulingAvailable && (
                            <ConditionalScheduling
                                platformId={platform.Id as PlatformId}
                                minuteOffset={
                                    platform.Id === "youtube" ? 20 : undefined
                                }
                                existingStreams={initialState?.existingStreams}
                                scheduleOptions={scheduleOptions}
                                selectedOption={formState}
                                setSelectedOption={updateScheduleState}
                            />
                        )}

                        {isTitleVisible && (
                            <TextInput
                                id="title"
                                type="text"
                                value={generalForm.title}
                                label={t("platforms:title")}
                                placeholder={t("platforms:title-placeholder")}
                                onChange={(e) =>
                                    onChange({ title: e.target.value })
                                }
                                maxLength={getInputMaxLength().title}
                            />
                        )}

                        {isDescriptionVisible && (
                            <TextArea
                                id="description"
                                value={generalForm.description}
                                label={t("platforms:event-description")}
                                placeholder={t("platforms:event-description")}
                                rows={5}
                                maxLength={getInputMaxLength().description}
                                onChange={(e) =>
                                    onChange({ description: e.target.value })
                                }
                            />
                        )}

                        <FormSection
                            platform={platform}
                            destinationId={destinationId}
                            destinationType={destinationType}
                        />

                        <QualitySelector />
                    </>
                ) : (
                    <>
                        {Object.keys(initialState).length > 0 && (
                            <NoAccess platformId={platform.Id as PlatformId} />
                        )}
                    </>
                )}
            </FormWrapper>
        )
    );
};
