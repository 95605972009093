import React, { useMemo } from "react";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { GatedContentPassFormProps, PricingModalTypes } from ".";
import { Errors } from "hooks/useForm";
import { CheckboxInput } from "components/inputs/checkbox-input";

const timedAccessPassesEnabled =
    import.meta.env.VITE_ENABLE_OTP_EXPANSION === "true";
export const OneTimePassField = ({
    values,
    errors,
    onChange,
    type,
    untimedOTPInitiallySelected
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (key: string, val: any) => void;
    type: PricingModalTypes;
    untimedOTPInitiallySelected: boolean;
}) => {
    const { t } = useTranslation();

    // If the old product is a one time pass with no duration,
    // and the user is updating it to a timed access pass,
    // we want to show a warning message
    const showTimedAccessPassWarning = useMemo(() => {
        const isUpdatingExistingProduct = type === PricingModalTypes.Update;
        const newPriceIsTimedAccessPass =
            values?.selectedPassType === "one-time" &&
            values?.oneTimeDurationSelected;

        return (
            timedAccessPassesEnabled &&
            isUpdatingExistingProduct &&
            untimedOTPInitiallySelected &&
            newPriceIsTimedAccessPass
        );
    }, [type, values, untimedOTPInitiallySelected]);

    return (
        <>
            <div className={`${mpstyles["pricing-form-expanded-content"]}`}>
                <div className={mpstyles["one-time-pass-price-container"]}>
                    <CheckboxInput
                        showCheckbox={false}
                        inputId="price"
                        inputName={t("gated-content-page:price")}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE}
                        value={values?.oneTimePrice}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        onInputChange={(e) => {
                            onChange("oneTimePrice", e?.currentTarget?.value);
                        }}
                        error={errors.oneTimePrice}
                        disabled={false}
                    />
                    {timedAccessPassesEnabled && (
                        <CheckboxInput
                            checkBoxId="timed-access-checkbox"
                            inputId="timed-access-input"
                            inputName={t("misc:timed-access-price")}
                            checked={values?.oneTimeDurationSelected}
                            onCheckboxChange={() =>
                                onChange(
                                    "oneTimeDurationSelected",
                                    !values?.oneTimeDurationSelected
                                )
                            }
                            onInputChange={(e) => {
                                onChange(
                                    "oneTimeDuration",
                                    Math.floor(
                                        parseInt(e?.currentTarget?.value)
                                    )
                                );
                            }}
                            inputType="number"
                            showCurrencyIndicator={false}
                            min={1}
                            placeholder={t(
                                "gated-content-modal:duration-placeholder"
                            )}
                            value={values?.oneTimeDuration ?? 0}
                            error={errors.oneTimeDuration}
                            disabled={!values?.oneTimeDurationSelected}
                            onDropdownChange={(e) => {
                                onChange(
                                    "oneTimeDurationUnits",
                                    e?.currentTarget?.value
                                );
                            }}
                            dropdownId="timed-access-dropdown"
                            dropdownValue={values?.oneTimeDurationUnits}
                            dropdownOptions={["Hours", "Days", "Weeks"]}
                        />
                    )}
                </div>
                {showTimedAccessPassWarning && (
                    <div
                        className={`${mpstyles["sub-error"]} ${mpstyles["pass-duration-warning"]}`}
                    >
                        {t("gated-content-modal:timed-access-pass-warning")}
                    </div>
                )}
            </div>
        </>
    );
};
