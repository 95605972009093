import styles from "./index.module.scss";
import ThreeDotsIcon from "assets/icons/more-vertical.svg?react";
import { ReactNode } from "react";
import { Dropdown, DropdownButton, DropdownButtonProps } from "react-bootstrap";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";

export interface ContextMenuItem {
    key: string;
    text: string;
    onClick: (...args: any) => any;
}

export interface ContextMenuProps {
    items: ContextMenuItem[];
    dropDirection?: DropDirection;
    icon?: ReactNode;
    buttonProps?: Partial<DropdownButtonProps>;
    showBackground?: boolean;
}

export const ContextMenu = ({
    items,
    dropDirection = "end",
    icon = <ThreeDotsIcon />,
    buttonProps,
    showBackground = true
}: ContextMenuProps) => {
    return (
        <DropdownButton
            drop={dropDirection}
            align={"end"}
            flip={false}
            title={icon}
            className={`${styles["context-menu"]} ${
                showBackground ? styles["show-background"] : ""
            }`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            rootCloseEvent={"mousedown"}
            {...buttonProps}
        >
            {items.map((item) => {
                return (
                    <Dropdown.Item
                        key={item.key}
                        title={item.key}
                        className={styles["context-menu-item"]}
                        onClick={() => {
                            item.onClick && item.onClick();
                        }}
                    >
                        {item.text}
                    </Dropdown.Item>
                );
            })}
        </DropdownButton>
    );
};
