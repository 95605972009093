import React from "react";
import styles from "./index.module.scss";
import HubSpotForm from "components/forms/HubSpotForm";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export interface HubSpotSurveyStepProps {
    nextStep: () => void;
    hubSpotFormId: string;
    headline: string;
    headlineBold: string;
}

export const HubSpotSurveyStep: React.FC<HubSpotSurveyStepProps> = ({
    nextStep,
    hubSpotFormId,
    headline,
    headlineBold
}) => {
    const { userInfo } = useSelector((state: RootState) => state.user);

    return (
        <div className={styles["survey-container"]}>
            <h2>
                {headline}
                <strong>{headlineBold}</strong>
            </h2>

            <HubSpotForm
                formId={hubSpotFormId}
                onFormSubmitted={() => nextStep()}
                onFormReady={(form) => {
                    form.querySelector('input[name="email"]').value =
                        userInfo?.Email;
                    form.querySelector('input[name="user_id"]').value =
                        userInfo?.UserId;
                }}
            />
        </div>
    );
};
