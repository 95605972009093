import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { RootState } from "store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { ModalBase } from "../ModalBase";
import { useTranslation } from "react-i18next";
import { Wizard } from "components/wizards/Wizard";
import { SelectPlanStep } from "./steps/SelectPlanStep";
import { CheckoutStep } from "./steps/CheckoutStep";
import {
    GetInvoiceResponse,
    SilverSunnStripeCreditCard,
    SilverSunnStripeCustomer,
    SilverSunnStripeSubscription,
    StripeInvoiceBindingModel,
    StripePrice,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { exists } from "helpers/booleans";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { useRunOnce } from "hooks/useRunOnce";
import { useSettableMemo } from "hooks/useSettableMemo";
import { useCheckout } from "hooks/useCheckout";
import rollbar from "helpers/rollbar";
import { StripeError } from "@stripe/stripe-js";

export enum ManagePlanModalSteps {
    SelectPlan = "select-plan",
    Checkout = "checkout"
}

export interface ManagePlanModalProps {
    prices: StripePrice[];
    preselectedPrice?: StripePrice | StripePriceAnonymous;
    couponCode?: string;
    stripePricesLoading: boolean;
    customer: SilverSunnStripeCustomer;
    licensedSubscription: SilverSunnStripeSubscription;
    lastAttemptedInvoice: StripeInvoiceBindingModel | GetInvoiceResponse;
    isPastDue: boolean;
    onCheckoutSuccess: (args?: {
        message?: string;
        selectedPaymentMethodId: string;
    }) => void;
    onCheckoutError: (
        message: string,
        args: { selectedPaymentMethodId: string; stripeError?: StripeError }
    ) => void;
    onCancel: () => Promise<void>;
}

export const ManagePlanModal = ({
    prices,
    preselectedPrice,
    couponCode,
    stripePricesLoading,
    customer,
    licensedSubscription,
    isPastDue,
    lastAttemptedInvoice,
    onCheckoutSuccess,
    onCheckoutError,
    onCancel
}: ManagePlanModalProps) => {
    const { userInfo } = useSelector((s: RootState) => s.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedPlan, setSelectedPlan] = useState<
        StripePrice | StripePriceAnonymous | undefined
    >(
        isPastDue
            ? prices?.find((p) => p.Id === licensedSubscription?.PlanId)
            : undefined
    );

    /** if the currentPlan !isPublic, it won't be selected in the modal */
    const currentPlan = useMemo<StripePrice>(
        () => prices?.filter((price) => price.Id === userInfo?.PlanId)?.[0],
        [prices, userInfo?.PlanId]
    );
    const [localSelectedPlan, setLocalSelectedPlan] = useState<
        StripePrice | StripePriceAnonymous | undefined
    >(currentPlan);

    const [paymentMethodReady, setPaymentMethodReady] =
        useState<boolean>(false);

    const [invoice, setInvoice] = useState<
        StripeInvoiceBindingModel | GetInvoiceResponse
    >(lastAttemptedInvoice);

    const activeStep = useMemo<ManagePlanModalSteps>(
        () =>
            !selectedPlan
                ? ManagePlanModalSteps.SelectPlan
                : ManagePlanModalSteps.Checkout,
        [selectedPlan]
    );

    useEffect(() => {
        if (preselectedPrice) {
            /** if the url contains a planId, this takes the user directly to the checkout step */
            setSelectedPlan(preselectedPrice);
            /** enables the Subscribe button */
            setLocalSelectedPlan(preselectedPrice);
        }
    }, [preselectedPrice]);

    const [addingNewPaymentMethod, setAddingNewPaymentMethod] =
        useState<boolean>(false);

    const { data: proration, dispatchApiRequest: getProrationAmounts } =
        useSwitcherClient((client) => client.stripe_GetProration, {
            requestImmediately: false,
            onError: (e) => rollbar.error("Error getting proration amounts", e)
        });

    const { dispatchApiRequest: postInvoice } = useSwitcherClient(
        (client) => client.stripeInvoices_PostInvoice,
        {
            requestImmediately: false
        }
    );

    const {
        claimedCoupon,
        addClaimedCoupon,
        removeClaimedCoupon,
        loading: loadingCoupon,
        error: couponError
    } = useGetLastClaimedCoupon({
        planId: selectedPlan?.Id,
        onChange: (claimedCoupon) =>
            !lastAttemptedInvoice &&
            !!selectedPlan?.Id &&
            getProrationAmounts([
                selectedPlan?.Id,
                1,
                customer?.StripeSubscriptions?.[0]
                    ?.SilverSunnStripeSubscriptionId,
                claimedCoupon?.ResellerInventoryItem.Id
            ])
    });

    const paymentMethodIsRequired = useMemo<boolean>(
        () =>
            proration?.AmountDue > 0 ||
            (claimedCoupon?.ResellerInventoryItem?.ResellerInventory
                ?.IsPaymentMethodRequired ??
                true),
        [claimedCoupon, proration]
    );

    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useSettableMemo<SilverSunnStripeCreditCard>(
            () =>
                paymentMethodIsRequired
                    ? customer?.StripeCreditCards.find((cc) => cc.Default)
                    : null,
            [customer]
        );

    useRunOnce(() => {
        setPaymentMethodReady(true);
    }, exists(selectedPaymentMethod) || !paymentMethodIsRequired);

    const { reset } = useRunOnce(async () => {
        if (!isPastDue) {
            selectedPlan.IsRecurring
                ? await getProrationAmounts([
                      selectedPlan?.Id,
                      1,
                      customer?.StripeSubscriptions?.[0]
                          ?.SilverSunnStripeSubscriptionId,
                      claimedCoupon?.ResellerInventoryItem.Id
                  ])
                : setInvoice(
                      await postInvoice([
                          userInfo.UserId,
                          {
                              InvoiceItems: [{ Price: selectedPlan?.Id }],
                              ResellerInventoryItemId:
                                  claimedCoupon?.ResellerInventoryItem.Id
                          }
                      ])
                  );
        }
    }, exists(customer) && exists(selectedPlan));

    const checkCouponToRemove = useCallback(() => {
        if (!!claimedCoupon?.ToPlan) {
            removeClaimedCoupon();
        }
    }, [claimedCoupon, removeClaimedCoupon]);

    const close = useCallback(() => {
        checkCouponToRemove();
        setSelectedPlan(undefined);
        dispatch(
            setActiveModal({
                id: Modals.None,
                type: Modals.None
            })
        );
    }, [dispatch, checkCouponToRemove]);

    const successDisabled = useMemo<boolean>(() => {
        if (loadingCoupon) return true;
        switch (activeStep) {
            case ManagePlanModalSteps.SelectPlan:
                // There is no selected plan, then is disabled
                if (localSelectedPlan === undefined) return true;
                // Otherwise, if the plan is cancelled or the user is trialing
                else if (
                    userInfo?.CancelAtPeriodEnd ||
                    userInfo.Status === "trialing" ||
                    couponCode
                ) {
                    // They can select whichever plan they want
                    return false;
                } else {
                    // Otherwise they can't select the plan they are currently on
                    return localSelectedPlan?.Id === currentPlan?.Id;
                }
            case ManagePlanModalSteps.Checkout:
                if (!paymentMethodIsRequired) return false;
                return !selectedPaymentMethod && !paymentMethodReady;
        }
    }, [
        activeStep,
        couponCode,
        currentPlan?.Id,
        loadingCoupon,
        localSelectedPlan,
        paymentMethodIsRequired,
        paymentMethodReady,
        selectedPaymentMethod,
        userInfo
    ]);

    /** Call handleSubmit function from child component */
    const handleSelectPlanSubmit = useCallback(
        (onHandleSubmit: () => void) => {
            /** !stripePricesLoading stops this from running and throwing errors on initial render */
            if (!stripePricesLoading) {
                onHandleSubmit();
            }
        },
        [stripePricesLoading]
    );

    const { checkout, isSubmitting } = useCheckout({
        licensedSubscription,
        resellerInventoryItemId: claimedCoupon?.ResellerInventoryItem?.Id,
        plan: selectedPlan,
        isNewSubscription: !licensedSubscription,
        isTrialing: userInfo.Status === "trialing",
        lastAttemptedInvoice,
        onSuccess: ({ selectedPaymentMethodId }) => {
            close();
            onCheckoutSuccess({ selectedPaymentMethodId });
        },
        onError: (error, args) => {
            reset();
            onCheckoutError(error, args);
        }
    });

    return (
        <ModalBase
            isOpen
            header={t("subscription:manage-plan")}
            onSuccess={() =>
                activeStep === "select-plan"
                    ? setSelectedPlan(localSelectedPlan)
                    : checkout({
                          selectedPaymentMethodId: addingNewPaymentMethod
                              ? "new-payment-method"
                              : selectedPaymentMethod?.SilverSunnStripeCreditCardId,
                          invoiceId: invoice?.id,
                          planId: selectedPlan?.Id
                      })
            }
            successDisabled={successDisabled}
            successButton={
                activeStep === "select-plan"
                    ? t("buttons:update")
                    : t("buttons:subscribe")
            }
            shouldNotCloseOnSuccess={true}
            onDismiss={close}
            dismissButton={t("buttons:cancel")}
            loading={isSubmitting}
        >
            <div className={styles["container"]}>
                <Wizard<ManagePlanModalSteps>
                    activeStep={activeStep}
                    steps={[
                        {
                            id: ManagePlanModalSteps.SelectPlan,
                            component: (
                                <SelectPlanStep
                                    loading={stripePricesLoading}
                                    prices={prices}
                                    currentPlan={userInfo?.PlanId}
                                    selectPlan={(plan) => {
                                        setLocalSelectedPlan(plan);
                                    }}
                                    onHandleSubmit={handleSelectPlanSubmit}
                                    onCancel={onCancel}
                                />
                            )
                        },
                        {
                            id: ManagePlanModalSteps.Checkout,
                            component: (
                                <CheckoutStep
                                    selectedPlan={selectedPlan}
                                    unselectPlan={() => {
                                        checkCouponToRemove();
                                        reset();
                                        setSelectedPlan(undefined);
                                    }}
                                    customer={customer}
                                    proration={proration}
                                    invoice={invoice}
                                    isPastDue={isPastDue}
                                    claimedCoupon={claimedCoupon}
                                    loadingCoupon={loadingCoupon}
                                    addingNewPaymentMethod={
                                        addingNewPaymentMethod
                                    }
                                    setAddingNewPaymentMethod={
                                        setAddingNewPaymentMethod
                                    }
                                    selectedPaymentMethod={
                                        selectedPaymentMethod
                                    }
                                    setSelectedPaymentMethod={
                                        setSelectedPaymentMethod
                                    }
                                    couponError={couponError}
                                    addClaimedCoupon={addClaimedCoupon}
                                    removeClaimedCoupon={removeClaimedCoupon}
                                    paymentMethodIsRequired={
                                        paymentMethodIsRequired
                                    }
                                    onPaymentMethodChange={(id, event) => {
                                        if (id !== "new-payment-method") {
                                            setPaymentMethodReady(true);
                                        } else {
                                            setPaymentMethodReady(
                                                event.complete
                                            );
                                        }
                                    }}
                                />
                            )
                        }
                    ]}
                />
            </div>
        </ModalBase>
    );
};
