import yt from "./youtube-helper";

export const youtube = {
    channels: {
        list: (fields) =>
            yt({
                resource: "channels",
                fields
            })
    },
    liveBroadcasts: {
        list: (fields) =>
            yt({
                resource: "liveBroadcasts",
                fields
            }),
        update: (fields, body) =>
            yt({
                method: "PUT",
                resource: "liveBroadcasts",
                fields,
                body
            }),
        insert: (fields, body) =>
            yt({
                method: "POST",
                resource: "liveBroadcasts",
                fields,
                body: body
            }),
        delete: (fields) =>
            yt({
                method: "DELETE",
                resource: "liveBroadcasts",
                fields
            }),
        bind: (fields) =>
            yt({
                method: "POST",
                resource: "liveBroadcasts/bind",
                fields
            }),
        transition: (fields) =>
            yt({
                method: "POST",
                resource: "liveBroadcasts/transition",
                fields
            })
    },
    liveStreams: {
        list: (fields) =>
            yt({
                resource: "liveStreams",
                fields
            }),
        insert: (fields, body) =>
            yt({
                method: "POST",
                resource: "liveStreams",
                fields,
                body: body
            })
    },
    thumbnails: {
        set: (fields, file) =>
            yt({
                method: "POST",
                resource: "thumbnails/set",
                fields,
                body: file,
                headers: {
                    "Content-Type": file.type
                },
                shouldSerializeBody: false
            })
    },
    helpers: {
        getLiveBroadcastsByStatus: async (broadcastStatus, pageToken?) => {
            const options = {
                maxResults: 4,
                part: "id,snippet,contentDetails",
                broadcastStatus,
                broadcastType: "event"
            };

            if (!!pageToken) options["pageToken"] = pageToken;

            const broadcasts = await youtube.liveBroadcasts.list(options);
            return broadcasts;
        },
        getBroadcastById: async (id) => {
            const broadcasts = await youtube.liveBroadcasts.list({
                part: "id,snippet,contentDetails,status",
                id
            });
            return broadcasts.items[0];
        },
        getStreamById: async (id) => {
            const streams = await youtube.liveStreams.list({
                part: "id, snippet, cdn, status",
                id: id
            });
            return streams.items[0];
        },
        createAndBindLiveStream: async (broadcastId, cdnFormat) => {
            const streamInfo = await youtube.helpers.createLiveStream(
                cdnFormat
            );
            const bindingInfo = await youtube.helpers.bindStream(
                streamInfo.id,
                broadcastId
            );
            return {
                streamInfo,
                bindingInfo
            };
        },
        createLiveStream: async (cdnFormat) => {
            const stream = {
                snippet: { title: cdnFormat },
                cdn: {
                    resolution: "variable",
                    frameRate: "variable",
                    ingestionType: "rtmp"
                },
                contentDetails: {
                    isReusable: false
                }
            };
            const streamInfo = await youtube.liveStreams.insert(
                { part: "id,snippet,status,cdn,contentDetails" },
                stream
            );
            return streamInfo;
        },
        bindStream: async (streamId, broadcastId) => {
            const bindResult = await youtube.liveBroadcasts.bind({
                id: broadcastId,
                part: "status",
                streamId: streamId
            });

            return bindResult;
        },
        unbindStream: async (id) => {
            const stream = await youtube.liveBroadcasts.bind({
                part: "status",
                id: id
            });
            return stream;
        },
        transitionToComplete: async (id) => {
            const broadcast = await youtube.liveBroadcasts.transition({
                part: "status",
                broadcastStatus: "complete",
                id: id
            });
            return broadcast;
        }
    }
};
