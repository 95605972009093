import { useMemo } from "react";
import { useClaimCheck } from "hooks/useClaimCheck";
import { parseBool } from "helpers/booleans";

export const useExpandedGating = () => {
    const hasGatedContentClaim = useClaimCheck("gatedcontent");
    const oneTimePassFeatureEnabled = parseBool(
        import.meta.env.VITE_ENABLE_OTP_EXPANSION
    );
    const hasPasswordProtectedContentClaim = useClaimCheck(
        "gatedcontent.password"
    );
    const hasEmailProtectedContentClaim = useClaimCheck("gatedcontent.email");

    const hasExtraGatingOptions = useMemo(
        () =>
            hasEmailProtectedContentClaim ||
            hasPasswordProtectedContentClaim ||
            oneTimePassFeatureEnabled,
        [
            hasEmailProtectedContentClaim,
            hasPasswordProtectedContentClaim,
            oneTimePassFeatureEnabled
        ]
    );

    return {
        hasGatedContentClaim,
        oneTimePassFeatureEnabled,
        hasPasswordProtectedContentClaim,
        hasEmailProtectedContentClaim,
        hasExtraGatingOptions
    };
};
