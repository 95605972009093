import { DownloadStep } from "./DownloadStep";
import { CreateAccountStep } from "./CreateAccountStep";
import { UploadVideoStep } from "./UploadVideoStep";
import { HubSpotSurveyStep } from "./HubSpotSurveyStep";
import { useTranslation } from "react-i18next";
const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

const NoSubscribeFlow = ({
    couponHook,
    stepsHook,
    clientId,
    redirectStatus,
    tag,
    defaultToSubscribeFlow,
    isGrowUser
}) => {
    const { t } = useTranslation("getting-started");

    const { resellerInventoryItem, handleCouponCode, removeCoupon } =
        couponHook;

    const { step, nextStep } = stepsHook;

    switch (step) {
        case 1:
            return (
                <CreateAccountStep
                    nextStep={nextStep}
                    clientId={clientId}
                    redirectStatus={redirectStatus}
                    tag={tag}
                    defaultToSubscribeFlow={defaultToSubscribeFlow}
                    resellerInventoryItem={resellerInventoryItem}
                    handleCouponCode={handleCouponCode}
                    removeCoupon={removeCoupon}
                />
            );
        case 2:
            if (isGrowUser === undefined) return <></>;
            return isGrowUser ? (
                <HubSpotSurveyStep
                    nextStep={nextStep}
                    hubSpotFormId="65ba3400-0214-4a82-925b-f04faaa7c13e"
                    headline={t("getting-started:survey-step:launch-title1")}
                    headlineBold={t(
                        "getting-started:survey-step:launch-title2"
                    )}
                />
            ) : (
                <HubSpotSurveyStep
                    nextStep={nextStep}
                    hubSpotFormId="8f4d7323-279e-4eae-8431-d867374f32ab"
                    headline={t("getting-started:survey-step:launch-title1")}
                    headlineBold={t(
                        "getting-started:survey-step:launch-title2"
                    )}
                />
            );
        case 3:
            if (isGrowUser === undefined) return <></>;
            return isGrowUser ? <UploadVideoStep /> : <DownloadStep />;
        case 4:
            if (skipDownloadStep) return <></>;
            return <DownloadStep />;
        default:
            return <></>;
    }
};

export default NoSubscribeFlow;
