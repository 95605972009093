import { useCallback, useEffect, useMemo, useState } from "react";
import {
    CreatorProduct,
    CreatorProductEntitlement,
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductEntitlementsRequest
} from "@switcherstudio/switcher-api-client";
import { Button } from "components/buttons/Button";
import { PricingSelectModal } from "components/modal/PricingSelectModal";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useCreatorProductEntitlement } from "hooks/useCreatorProductEntitlement";
import { useGetCreatorProducts } from "hooks/useGetCreatorProducts";
import {
    GatedContentStatus,
    useStripeAccountInfo
} from "hooks/useStripeAccountInfo";
import { Trans, useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { exists } from "helpers/booleans";
import { AttentionModal } from "components/modal/AttentionModal";
import { useNavigate } from "react-router-dom";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import PassEmptyState from "components/empty-state/PassEmptyState";
import { AttentionModalTable } from "components/modal/AttentionModal/AttentionModalTable";
import { GatedContentPageTabs } from "store/view/types";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import { Link } from "react-router-dom";
import { Spinner } from "components/spinners/Spinner";
import { useRunOnce } from "hooks/useRunOnce";

export interface CollectionGatedContentPageStripeSectionProps {
    videoPlayerId: string;
    onReset?: () => void;
    setHandleSubmit?: (handleSubmit: () => void) => void;
    setHasChanges?: (hasChanges: boolean) => void;
    setSelectedValue?: (selectedValue: string) => void;
    disabled?: boolean;
}

export const CollectionGatedContentPageStripeSection = ({
    videoPlayerId,
    onReset,
    setHandleSubmit,
    setHasChanges,
    setSelectedValue,
    disabled = false
}: CollectionGatedContentPageStripeSectionProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        oneTimeProducts,
        recurringProducts,
        products,
        loading: productsLoading
    } = useGetCreatorProducts();
    const [localEntitlement, setLocalEntitlement] =
        useState<CreatorProductEntitlementsRequest>();

    const hasRecurringPassesClaim = useClaimCheck(
        "gatedcontent.recurringpayments"
    );

    const [pricingSelectModalOpen, setPricingSelectModalOpen] =
        useState<boolean>(false);
    const [attentionModalOpen, setAttentionModalOpen] =
        useState<boolean>(false);
    const [existingEntitlement, setExistingEntitlement] =
        useState<CreatorProductEntitlement>();

    const [loading, setLoading] = useState<boolean>(true);

    const {
        account,
        gatedContentStatus,
        loading: stripeLoading
    } = useStripeAccountInfo({
        expandDetails: true
    });

    const stripeConnected = useMemo(
        () => gatedContentStatus === GatedContentStatus.READY,
        [gatedContentStatus]
    );

    const resetData = useCallback(() => {
        setExistingEntitlement(null);
        setAttentionModalOpen(false);
        setPricingSelectModalOpen(false);
        onReset?.();
    }, [onReset]);

    const {
        loading: creatorProductEntitlementLoading,
        videoPlayer,
        getVideoPlayer,
        addCreatorProductEntitlement,
        deleteCreatorProductEntitlement
    } = useCreatorProductEntitlement(videoPlayerId, {
        suppressNotifications: true,
        onAdd: resetData,
        onDelete: resetData
    });

    useRunOnce(
        () => setLoading(false),
        !creatorProductEntitlementLoading && !productsLoading
    );

    const videoPlayerEntitlement = useMemo<CreatorProductEntitlement>(
        () =>
            videoPlayer?.VideoPlayerEntitlements.ProductEntitlements.find(
                (e) => e.Product.IsActive && e.Product.Prices
            ),
        [videoPlayer]
    );

    const openPricingModal = useCallback(() => {
        setPricingSelectModalOpen(true);
    }, []);

    const productHasEntitlement = exists(videoPlayerEntitlement);

    useEffect(() => {
        setLocalEntitlement(
            productHasEntitlement
                ? {
                      ProductEntitlements: [
                          {
                              ProductId:
                                  videoPlayer?.VideoPlayerEntitlements
                                      ?.ProductEntitlements?.[0]?.ProductId,
                              VideoPlayerId: videoPlayer?.ProjectId,
                              Discriminator:
                                  CreatorProductEntitlementsBindingModelDiscriminator._0
                          }
                      ]
                  }
                : undefined
        );
        setExistingEntitlement(videoPlayerEntitlement);
    }, [videoPlayer, productHasEntitlement, videoPlayerEntitlement]);

    const { dispatchApiRequest: getSubscriberCount } = useSwitcherClient(
        (client) => client.creatorProducts_GetSubscriberCount
    );

    const { dispatchApiRequest: getEntitlements } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_GetByProductId
    );

    const handleAssignment = useCallback(
        async (creatorProduct: CreatorProduct) => {
            if (creatorProduct !== undefined) {
                setLocalEntitlement({
                    ProductEntitlements: [
                        {
                            ProductId: creatorProduct?.Id,
                            VideoPlayerId: videoPlayerId,
                            Discriminator:
                                CreatorProductEntitlementsBindingModelDiscriminator._0
                        }
                    ]
                });
            }
        },
        [videoPlayerId]
    );

    const handleSubmit = useCallback(async () => {
        const productEntitlements =
            (await getEntitlements([existingEntitlement?.Product?.Id]))
                ?.ProductEntitlements ?? [];
        const productActiveSubscriptions = await getSubscriberCount([
            account?.Id,
            existingEntitlement?.Product?.Id
        ]);
        const isOnlyEntitlement =
            productEntitlements.length === 1 &&
            existingEntitlement?.Id === productEntitlements[0]?.Id &&
            productActiveSubscriptions > 0;

        const discriminator: CreatorProductEntitlementsBindingModelDiscriminator =
            videoPlayer?.VideoPlayerEntitlements?.ProductEntitlements?.[0]
                ?.Product?.Prices?.[0]?.IsRecurring
                ? CreatorProductEntitlementsBindingModelDiscriminator._0
                : CreatorProductEntitlementsBindingModelDiscriminator._2;

        if (!!localEntitlement) {
            // create new product entitlement
            switch (discriminator) {
                case CreatorProductEntitlementsBindingModelDiscriminator._0:
                    if (isOnlyEntitlement) {
                        throw new Error(
                            t(
                                "gated-content-modal:errors:pass-with-subscribers-error"
                            )
                        );
                    }
                    await addCreatorProductEntitlement([localEntitlement]);
                    break;
                case CreatorProductEntitlementsBindingModelDiscriminator._2:
                    await addCreatorProductEntitlement([localEntitlement]);
            }
        } else if (!!existingEntitlement) {
            if (isOnlyEntitlement) {
                throw new Error(
                    t("gated-content-modal:errors:pass-with-subscribers-error")
                );
            }
            await deleteCreatorProductEntitlement([existingEntitlement?.Id]);
        }
        resetData();
        getVideoPlayer();
    }, [
        account?.Id,
        addCreatorProductEntitlement,
        deleteCreatorProductEntitlement,
        existingEntitlement,
        getEntitlements,
        getSubscriberCount,
        getVideoPlayer,
        localEntitlement,
        resetData,
        t,
        videoPlayer
    ]);

    // Pass the handleSubmit function to the parent component
    useEffect(() => {
        setHandleSubmit(() => handleSubmit);
    }, [setHandleSubmit, handleSubmit]);

    const hasChanges = useMemo<boolean>(() => {
        return (
            localEntitlement?.ProductEntitlements?.[0]?.ProductId !==
            existingEntitlement?.Product?.Id
        );
    }, [localEntitlement, existingEntitlement]);

    useEffect(() => {
        setHasChanges(hasChanges);
    }, [hasChanges, setHasChanges]);

    const filteredProducts = useMemo<CreatorProduct[]>(() => {
        if (!oneTimeProducts && !recurringProducts) return [];
        const recurringFiltered =
            recurringProducts?.filter((p) => p.Name !== "Catalog Pass") ?? [];
        return [...(oneTimeProducts ?? []), ...recurringFiltered];
    }, [recurringProducts, oneTimeProducts]);

    /** this needed to be set in state because we don't PUT
     * the new pass til we click the save button, but we need the name
     * to be correct once the user has selected a new pass in the modal */
    const passName = useMemo<string>(() => {
        if (
            localEntitlement?.ProductEntitlements?.[0]?.ProductId !== undefined
        ) {
            return filteredProducts?.find(
                (fp) =>
                    fp.Id ===
                    localEntitlement?.ProductEntitlements?.[0]?.ProductId
            )?.Name;
        } else if (localEntitlement === undefined) {
            return t("video-player-settings:add-pricing");
        }
        return t("video-player-settings:add-pricing");
    }, [localEntitlement, filteredProducts, t]);

    const selectPass = useCallback(
        async (selection) => {
            // do nothing if it's the same as what was selected before
            if (
                selection?.Id === existingEntitlement?.Product?.Id ||
                !selection
            ) {
                setLocalEntitlement(selection);
            }

            handleAssignment(selection);
        },
        [existingEntitlement, handleAssignment]
    );

    useEffect(() => {
        setSelectedValue(localEntitlement?.ProductEntitlements?.[0]?.ProductId);
    }, [localEntitlement, setSelectedValue]);

    const { link: stripeConnectLink } = useGetStripeConnectLink();

    return (
        <div className={styles["page-content"]}>
            {stripeLoading || loading ? (
                <div className={styles["loading"]}>
                    <Spinner size={20} />
                </div>
            ) : (
                <>
                    {!stripeConnected ? (
                        // 1. Stripe not connected
                        <PassEmptyState
                            title={t(
                                "players:gated-content:connect-stripe-account-title"
                            )}
                            message={t(
                                "players:gated-content:connect-stripe-account-description"
                            )}
                            linkText={t(
                                "players:gated-content:connect-stripe-account-link-text"
                            )}
                            linkHref={stripeConnectLink}
                        ></PassEmptyState>
                    ) : (
                        hasRecurringPassesClaim && (
                            <>
                                {filteredProducts?.length > 0 ? (
                                    // 2. Stripe connected, passes created
                                    <div
                                        className={`${styles["pass-container"]} ${styles["recurring-pass"]}`}
                                    >
                                        <Button
                                            disabled={
                                                gatedContentStatus !==
                                                    GatedContentStatus.READY ||
                                                disabled
                                            }
                                            type="badge"
                                            isActive={!!localEntitlement}
                                            onClick={() => {
                                                openPricingModal();
                                            }}
                                        >
                                            {passName}
                                        </Button>
                                    </div>
                                ) : (
                                    // 3. Stripe connected, but no passes created yet.
                                    <div>
                                        <Trans
                                            i18nKey={
                                                "gated-content-page:collection-create-pass"
                                            }
                                            components={{
                                                link1: (
                                                    <Link
                                                        to={
                                                            "/gated-content#passes"
                                                        }
                                                        title="Manage Gated Content Passes"
                                                        className={
                                                            styles["link"]
                                                        }
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    )}

                    {/* Unconditionally Rendered Modals */}

                    <PricingSelectModal
                        isOpen={pricingSelectModalOpen}
                        setIsOpen={setPricingSelectModalOpen}
                        handleSelect={(selected) => selectPass(selected)}
                        products={products}
                        selected={
                            localEntitlement?.ProductEntitlements?.[0]
                                ?.ProductId
                        }
                        buttonText={t("buttons:done")}
                    />

                    <AttentionModal
                        isOpen={attentionModalOpen}
                        setIsOpen={setAttentionModalOpen}
                        handleContinue={() => {
                            setPricingSelectModalOpen(false);
                            handleAssignment(
                                localEntitlement?.ProductEntitlements?.[0]
                            );
                        }}
                        handleCancel={() => {
                            setPricingSelectModalOpen(true);
                            setAttentionModalOpen(false);
                        }}
                        updateModal={() =>
                            navigate(
                                `/gated-content#${GatedContentPageTabs.Pricing}`,
                                {
                                    state: {
                                        searchParams: {
                                            pass: videoPlayerEntitlement.ProductId
                                        }
                                    }
                                }
                            )
                        }
                    >
                        <>
                            <div>
                                <p
                                    className={`${styles["undo-mb"]} ${styles["padding-bottom"]}`}
                                >
                                    {t("gated-content-page:attention-body")}
                                </p>
                            </div>
                            <AttentionModalTable
                                players={[videoPlayer]}
                                updateModal={() =>
                                    navigate(
                                        `/gated-content#${GatedContentPageTabs.Pricing}`,
                                        {
                                            state: {
                                                searchParams: {
                                                    pass: videoPlayerEntitlement.ProductId
                                                }
                                            }
                                        }
                                    )
                                }
                            />

                            <div className={`${styles["undo-mb"]}`}>
                                <p
                                    className={`${styles["undo-mb"]} ${styles["padding-top"]}`}
                                >
                                    {t("gated-content-page:attention-action")}
                                </p>
                            </div>
                        </>
                    </AttentionModal>
                </>
            )}
        </div>
    );
};
