import React from "react";
import { useTranslation } from "react-i18next";
import { usePageHeader } from "hooks/usePageHeader";
import { useClaimCheck } from "hooks/useClaimCheck";
import { GatedContentPageStripeSection } from "./GatedContentPageStripeSection";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";

export const GatedContentPage: React.FC = () => {
    const { t } = useTranslation();

    const hasCatalogClaim = useClaimCheck("catalog");
    const enablePayPerView = useClaimCheck("gatedcontent");

    usePageHeader({
        title: t("page-titles:gated-content"),
        subTitle: hasCatalogClaim
            ? t("page-titles:subheaders:gated-content-catalog")
            : t("page-titles:subheaders:gated-content")
    });

    return (
        <>
            {enablePayPerView ? (
                <GatedContentPageStripeSection />
            ) : (
                <StudioUpgradeCta variant={"gated-content"} />
            )}
        </>
    );
};
