import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { removeKey } from "helpers/records";
import { ResumableUploadsState } from "./types";

export const initialState: ResumableUploadsState = {
    storedFiles: {}
};

export const resumableUploads = createSlice({
    name: "resumableUploads",
    initialState,
    reducers: {
        addStoredFile: (
            state,
            {
                payload: { fileId, streamMediaId }
            }: PayloadAction<{ fileId: string; streamMediaId: string }>
        ) => {
            return {
                ...state,
                storedFiles: {
                    ...state.storedFiles,
                    [fileId]: { streamMediaId }
                }
            };
        },
        /**Removes file from stored file by file id */
        removeStoredFile: (
            { storedFiles },
            { payload }: PayloadAction<string>
        ) => {
            return { storedFiles: removeKey(storedFiles, payload) };
        }
    }
});

export const { addStoredFile, removeStoredFile } = resumableUploads.actions;

export default resumableUploads.reducer;
