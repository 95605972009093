/**
 * Removes keys from the first record that are present in the second record.
 *
 * @param first - The original record.
 * @param second - The record containing keys to remove from the first record.
 * @returns A new record with specified keys removed.
 */
export const removeKeys = <T>(
    first: Record<string, T>,
    second: Record<string, T>
): Record<string, T> => {
    const result = { ...first };
    Object.keys(second).forEach((key) => {
        if (key in result) {
            delete result[key];
        }
    });
    return result;
};

/**
 * Returns a copy of the record with the key removes.
 *
 * @param record - The original record.
 * @param key - the key to remove
 * @returns A new record with specified key removed.
 */
export const removeKey = <T>(
    record: Record<string, T>,
    key: string
): Record<string, T> => {
    const clone = { ...record };
    delete clone[key];

    return clone;
};
