import { useSelector } from "react-redux";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import HubSpotForm from "components/forms/HubSpotForm";
import { RootState } from "store/reducers";
import { Dispatch, SetStateAction } from "react";

interface HubSpotModalProps {
    onSuccess: () => void;
    hubSpotFormId: string;
    header: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const HubSpotModal: React.FC<HubSpotModalProps> = ({
    onSuccess,
    hubSpotFormId,
    header,
    isOpen,
    setIsOpen
}) => {
    const { userInfo } = useSelector((state: RootState) => state.user);

    return (
        <div className={styles["hubspot-modal"]}>
            <ModalBase
                header={header}
                successButton={undefined}
                dismissButton={undefined}
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
            >
                <HubSpotForm
                    formId={hubSpotFormId}
                    onFormSubmitted={() => {
                        setIsOpen(false);
                        onSuccess();
                    }}
                    onFormReady={(form) => {
                        form.querySelector('input[name="email"]').value =
                            userInfo?.Email;
                        form.querySelector('input[name="user_id"]').value =
                            userInfo?.UserId;
                    }}
                />
            </ModalBase>
        </div>
    );
};
