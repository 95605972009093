import { switcherSdk } from "utils/switcher-sdk";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { setTicket } from "store/user/slice";
import { getUserInfo, getUserOrgs } from "store/user/thunks";
import { RootState } from "store/reducers";
import { setAppVersion } from "store/app/slice";
import { configureRollbar } from "store/rollbar/slice";

export function useSwitcherSdk(shouldAuth: boolean = false) {
    const dispatch = useDispatch<AppDispatch>();
    const { version } = useSelector((s: RootState) => s.app);

    const isInBrowser = switcherSdk.clientId === "Generic";

    useEffect(() => {
        function checkVersion() {
            const appVersion = import.meta.env.VITE_VERSION;

            if (!version || appVersion !== version) {
                switcherSdk.clearCache();
                dispatch(setAppVersion(appVersion));
            }
        }

        if (isInBrowser) {
            return;
        }

        checkVersion();
    }, [dispatch, isInBrowser, version]);

    useEffect(() => {
        async function getAuthFromApp() {
            if (switcherSdk.auth?.accessToken) {
                dispatch(
                    setTicket({
                        access_token: switcherSdk.auth.accessToken,
                        accessGranted: "true",
                        userId: switcherSdk.auth.userId
                    })
                );

                const userInfo = await dispatch(getUserInfo()).unwrap();

                dispatch(
                    configureRollbar({
                        UserId: userInfo.UserId
                    })
                );

                if (userInfo.OrganizationId) {
                    dispatch(getUserOrgs());
                }
            }
        }

        if (isInBrowser || !shouldAuth) {
            return;
        }

        getAuthFromApp();
    }, [isInBrowser, shouldAuth, dispatch]);

    useEffect(() => {
        function hideCover() {
            switcherSdk.hideCover();
        }

        if (isInBrowser) {
            return;
        }

        hideCover();
    }, [isInBrowser]);

    return { isInBrowser, switcherSdk };
}
