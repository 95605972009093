import React, { useEffect } from "react";
import { useSpeedTest } from "hooks/useSpeedTest";
import { SomTestStatus } from "views/page-content/platforms/types";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { updateGeneralForm } from "store/platforms/slice";

interface SpeedTestProps {
    onComplete: (channel: ChannelSetting) => void;
    hasFullHD: boolean;
}

export const SpeedTest: React.FC<SpeedTestProps> = ({
    onComplete,
    hasFullHD
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { createChannelSetting, startSomSpeedTest, somData, somTestStatus } =
        useSpeedTest();

    useEffect(() => {
        if (somData.results) {
            dispatch(updateGeneralForm({ usedSpeedTest: true }));
            const channel = createChannelSetting(somData.results, hasFullHD);
            onComplete(channel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createChannelSetting, somData.results, hasFullHD]);

    const { t } = useTranslation("speed-test");
    return (
        <>
            <div className="col-sm-12">
                {somTestStatus === SomTestStatus.Completed &&
                    somData?.results && (
                        <div
                            className="alert alert-info text-center"
                            role="alert"
                        >
                            Speed test complete! <br />
                            <strong>
                                {t("upload")}: {somData.results.upload} Mbps
                            </strong>
                            <br />
                            <small>
                                {t("download")}: {somData.results.download} Mbps
                            </small>
                        </div>
                    )}
                {somTestStatus === SomTestStatus.Error && somData?.error && (
                    <div
                        className="alert alert-danger text-center"
                        role="alert"
                    >
                        {somData.error.message}
                    </div>
                )}
                <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={startSomSpeedTest}
                    disabled={somTestStatus === SomTestStatus.InProgress}
                >
                    {somTestStatus === SomTestStatus.InProgress &&
                    somData?.progress?.type ? (
                        <span
                            className={styles["loading-ellipsis"]}
                        >{`Testing ${somData.progress.type}`}</span>
                    ) : (
                        t("speed-test")
                    )}
                </button>
                <div className="col-sm-12 text-center">
                    <small>
                        {t("info-1")} <strong>{t("speed-test")}</strong>{" "}
                        {t("info-2")} <strong>{t("stream-quality")}</strong>{" "}
                        {t("info-3")}
                    </small>
                </div>
            </div>
        </>
    );
};
