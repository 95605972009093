import React, { useEffect, useState } from "react";
import { client } from "api/client";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useTranslation } from "react-i18next";
import {
    PlatformsTable,
    PlatformsTableStreamProvider
} from "components/platforms/PlatformsTable";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useAllPlatformActions } from "hooks/usePlatformActions";
import rollbar from "helpers/rollbar";
import { PlatformId } from "../platforms/types";

export const LinkAccountPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { isInBrowser, switcherSdk } = useSwitcherSdk();
    const [platforms, setPlatforms] = useState<StreamingProvider[]>([]);
    const { disconnect, canManage } = useAllPlatformActions();

    useEffect(() => {
        async function getPlatforms() {
            try {
                const platforms =
                    await client.streamingProviders_GetStreamingProviders(true);
                let providers = platforms.StreamingProviders;

                if (!isInBrowser && switcherSdk?.targetAspectRatio) {
                    // If in the app, filter available platforms by the target aspect ratio
                    const width = switcherSdk?.targetAspectRatio?.numer;
                    const height = switcherSdk?.targetAspectRatio?.denom;
                    providers = providers.filter((p) => {
                        return p.AspectRatios?.some(
                            (ar) => ar.Width === width && ar.Height === height
                        );
                    });
                }

                // Should we allow this provider to be managed?
                providers = providers.map(
                    (p): PlatformsTableStreamProvider => ({
                        ...p,
                        CanManage: canManage(p.Id as PlatformId)
                    })
                );

                setPlatforms(providers);
            } catch (e) {
                rollbar.error("Error getting available platforms", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message:
                            "There was a problem getting available platforms."
                    })
                );
            }
        }

        getPlatforms();
    }, [dispatch, isInBrowser, switcherSdk, canManage]);

    return (
        <>
            <PlatformsTable
                platforms={platforms}
                connectText={t("platforms:connect-button")}
                detailsText={t("buttons:manage")}
                disconnectText={t("platforms:disconnect-button")}
                disconnectAction={(platformId: PlatformId) =>
                    disconnect(platformId, platforms, setPlatforms)
                }
            />
        </>
    );
};
