import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { FileMap, VideoUploadSession } from "./types";
import rollbar from "helpers/rollbar";

export const cancelUploadSessions = createAsyncThunk<
    void,
    void,
    {
        state: RootState;
    }
>("videoUploadSession/exchangeCode", async (_, { getState }) => {
    const { uploadSessions } = getState().videoUploadSession;

    for (const sessionId in uploadSessions) {
        const session = uploadSessions[sessionId];

        for (const fileId in session.files) {
            const file = session.files[fileId];
            if (file.isUploading) {
                file.uppyInstance.cancelAll();
            }
        }

        session.entryPointDragAndDropInstance?.cancelAll();
        session.modalDragAndDropInstance?.cancelAll();
    }
});

export const setUploadingFiles = createAsyncThunk<
    {
        currentSession: VideoUploadSession;
        updatedFiles: FileMap;
    },
    {
        sessionId: string;
        uploadingFileIds: string[];
    },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>(
    "videoUploadSession/setUploadingFiles",
    async ({ sessionId, uploadingFileIds }, { getState }) => {
        const state = getState().videoUploadSession;
        const { storedFiles } = getState().resumableUploads;
        const { uploadSessions } = state;
        const currentSession = uploadSessions[sessionId];

        const updatedFiles: FileMap = uploadingFileIds.reduce(
            (memo: FileMap, currentFileId: string) => {
                try {
                    const file = currentSession?.files[currentFileId];

                    const streamMediaId =
                        file.streamMediaId ??
                        storedFiles?.[currentFileId]?.streamMediaId;

                    if (!streamMediaId) {
                        throw new Error("No streamMediaId found");
                    }

                    return {
                        ...memo,
                        [currentFileId]: {
                            ...file,
                            streamMediaId,
                            isUploading: true,
                            isAwaitingUpload: false
                        }
                    };
                } catch (error) {
                    rollbar.error("Error while triggering upload file", error);
                }
            },
            {}
        );

        return {
            currentSession,
            updatedFiles
        };
    }
);
