import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import CatalogGatingCTA from "assets/StudioUpgradeCTA_CatalogGating.png";
import CollectionGatingCTA from "assets/StudioUpgradeCTA_CollectionGating.png";
import GatedContentGatingCTA from "assets/StudioUpgradeCTA_GatedContent.png";
import AnalyticsGatingCTA from "assets/StudioUpgradeCTA_AnalyticsRevenueTab.png";
import styles from "./index.module.scss";

export const StudioUpgradeCta = ({
    variant
}: {
    variant: "catalog" | "collection" | "gated-content" | "analytics";
}) => {
    let ctaImageSrc = "";
    let ctaImageAlt = "";
    let ctaLink = "/subscription?planId=SSMOBUSINESS99V2#openModal";
    switch (variant) {
        case "collection":
            ctaImageSrc = CollectionGatingCTA;
            ctaImageAlt = `Earn directly from your supporters. Whether you make video content for your passion project or as a business, gated content with Switcher empowers you to earn revenue directly from fans. Create a pass, set your price, and then select the content you want to include.`;
            break;
        case "gated-content":
            ctaImageSrc = GatedContentGatingCTA;
            ctaImageAlt = `Boost your earnings with gated content. Enable gated content on any Switcher-hosted videos and boost your earnings — no minimum number of viewers needed. Create a pass, set your price, and then select the content you want to include. From generating leads to cultivating communities around your content, now you own the relationship with your customers.`;
            break;
        case "analytics":
            ctaImageSrc = AnalyticsGatingCTA;
            ctaImageAlt = `Use video analytics to grow your business. Use the analytics dashboard to learn videos are getting the most views and engagement. Monitor your success and use the built-in data to optimize your content.`;
            break;
        case "catalog":
            ctaImageSrc = CatalogGatingCTA;
            ctaImageAlt = `Turn your site into a video subscription platform. Amplify your earnings with video memberships. You build the content package, give it a name, and decide the price you want to charge for access. You work hard to make great content, now its time to make that content work for you.`;
            break;
        default:
    }

    return (
        <>
            <p className={styles.upgradeCta}>
                <Trans
                    i18nKey={"misc:studio-plan-upgrade-cta"}
                    components={{
                        link1: <Link to={ctaLink} />
                    }}
                />
            </p>
            {ctaImageSrc.length > 0 && (
                <Link to={ctaLink}>
                    <img
                        src={ctaImageSrc}
                        alt={ctaImageAlt}
                        className={styles.upgradeImage}
                    />
                </Link>
            )}
        </>
    );
};
