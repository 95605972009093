import React, { Dispatch, SetStateAction } from "react";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { CheckboxInput } from "components/inputs/checkbox-input";
import { GatedContentPassFormProps, PricingModalTypes } from ".";
import { Errors } from "hooks/useForm";
import { Button } from "components/buttons/Button";
import { Toggle } from "components/inputs/toggle/Toggle";

export const RecurringPassField = ({
    values,
    errors,
    onChange,
    setPlayerSelectModalIsOpen,
    buttonText,
    buttonActive,
    loading = false,
    variant,
    type
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (key: string, value: any) => void;
    setPlayerSelectModalIsOpen?: Dispatch<SetStateAction<boolean>>;
    buttonText?: string;
    buttonActive?: boolean;
    loading?: boolean;
    type: PricingModalTypes;
    variant?: "catalog";
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                className={`${mpstyles["pricing-form-expanded-content"]} ${
                    variant ? mpstyles[variant] : ""
                }`}
            >
                <div className={mpstyles["recurring-pass-prices-container"]}>
                    <CheckboxInput
                        checkBoxId="monthly-price-checkbox"
                        inputId="monthly-price-input"
                        inputName={
                            variant === "catalog"
                                ? t("misc:monthly-catalog-price")
                                : t("misc:monthly-price")
                        }
                        checked={values.recurringMonthlyPriceSelected}
                        onCheckboxChange={() => {
                            onChange(
                                "recurringMonthlyPriceSelected",
                                !values.recurringMonthlyPriceSelected
                            );
                        }}
                        onInputChange={(e) => {
                            onChange("recurringMonthlyPrice", e.target.value);
                        }}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_MONTHLY_PRICE}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        value={values.recurringMonthlyPrice}
                        error={errors.recurringMonthlyPrice}
                        disabled={
                            values?.recurringMonthlyPriceSelected === false
                        }
                    />
                    <CheckboxInput
                        checkBoxId="annual-price-checkbox"
                        inputId="annual-price-input"
                        inputName={
                            variant === "catalog"
                                ? t("misc:annual-catalog-price")
                                : t("misc:annual-price")
                        }
                        checked={values.recurringAnnualPriceSelected}
                        onCheckboxChange={() =>
                            onChange(
                                "recurringAnnualPriceSelected",
                                !values.recurringAnnualPriceSelected
                            )
                        }
                        onInputChange={(e) => {
                            onChange("recurringAnnualPrice", e.target.value);
                        }}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_ANNUAL_PRICE}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        value={values.recurringAnnualPrice}
                        error={errors.recurringAnnualPrice}
                        disabled={
                            values?.recurringAnnualPriceSelected === false
                        }
                    />
                </div>
                {setPlayerSelectModalIsOpen && buttonText && (
                    <>
                        <hr />
                        <div className={mpstyles["add-to-players-container"]}>
                            <p>{t("buttons:add-to-collections")}</p>
                            <Button
                                type="badge"
                                disabled={loading}
                                isActive={buttonActive}
                                onClick={() => setPlayerSelectModalIsOpen(true)}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </>
                )}
                {type === PricingModalTypes.Update && (
                    <>
                        <Toggle
                            label={t(
                                "gated-content:subscription-update:update-to-new-only"
                            )}
                            on={values.recurringApplySubscriptionUpdatesNewOnly}
                            onToggle={() =>
                                onChange(
                                    "recurringApplySubscriptionUpdatesNewOnly",
                                    !values.recurringApplySubscriptionUpdatesNewOnly
                                )
                            }
                            className={mpstyles["toggle"]}
                            reverseLayout={true}
                        />
                        {!values.recurringApplySubscriptionUpdatesNewOnly && (
                            <div
                                className={
                                    mpstyles[
                                        "recurring-update-subscribers-warning"
                                    ]
                                }
                            >
                                {t(
                                    "gated-content:subscription-update:update-to-new-only-warning"
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
