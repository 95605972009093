import styles from "./index.module.scss";

export interface SkeletonTextLoaderProps {
    lineCount?: number;
}

export const SkeletonTextLoader = ({
    lineCount = 1
}: SkeletonTextLoaderProps) => {
    return (
        <div className={styles["skeleton-text-loader"]}>
            {Array.from({ length: lineCount }).map((_, index) => (
                <div key={index} className={styles["skeleton-line"]} />
            ))}
        </div>
    );
};
