import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    BroadcastStatus,
    BroadcastWebLink,
    Category,
    CreatorProduct
} from "@switcherstudio/switcher-api-client";
import Uppy, { FailedUppyFile } from "@uppy/core";
import { VideoUploadSessionState, UploadedFile, FileMap } from "./types";
import { cancelUploadSessions, setUploadingFiles } from "./thunks";
import { getCurrentSession } from "./helpers";

const initialState: VideoUploadSessionState = {
    uploadSessions: {}
};

export const videoUploadSession = createSlice({
    name: "videoUploadSession",
    initialState: initialState,
    reducers: {
        setUppyInstances: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                entryPointDragAndDropInstance?: Uppy;
                modalDragAndDropInstance?: Uppy;
            }>
        ) => {
            // we cannot use the same instance for the entry point component's drag and drop and for the
            // drag and drop step in the modal because they could be rendered at the same time and would error

            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        entryPointDragAndDropInstance:
                            currentSession.entryPointDragAndDropInstance ??
                            payload?.entryPointDragAndDropInstance ??
                            null,
                        modalDragAndDropInstance:
                            currentSession.modalDragAndDropInstance ??
                            payload?.modalDragAndDropInstance ??
                            null
                    }
                }
            };
        },
        setSuccessfulUploads: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                successfullFileIds: string[];
            }>
        ) => {
            const successfulFiles = payload.successfullFileIds.reduce(
                (memo, currentFile) => ({
                    ...memo,
                    [currentFile]: {
                        ...state.uploadSessions[payload.sessionId]?.files[
                            currentFile
                        ],
                        isUploading: false,
                        isSuccess: true
                    }
                }),
                {} as FileMap
            );
            const currentSession = getCurrentSession(state, payload);

            const newTotalResolvedUploads =
                currentSession.totalResolvedUploads +
                payload.successfullFileIds.length;
            const allComplete =
                newTotalResolvedUploads ===
                Object.values(currentSession.files).length;

            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            ...successfulFiles
                        },
                        totalResolvedUploads: newTotalResolvedUploads,
                        allComplete
                    }
                }
            };
        },
        setFailedUploads: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                failedFiles: Pick<
                    FailedUppyFile<
                        Record<string, unknown>,
                        Record<string, unknown>
                    >,
                    "id" | "error"
                >[];
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            const failedFiles = payload.failedFiles.reduce(
                (memo, currentFile) => ({
                    ...memo,
                    [currentFile.id]: {
                        ...state.uploadSessions[payload.sessionId]?.files[
                            currentFile.id
                        ],
                        isUploading: false,
                        isSuccess: false,
                        isErrored: true,
                        error: currentFile.error
                    }
                }),
                {} as FileMap
            );

            const newTotalResolvedUploads =
                currentSession.totalResolvedUploads +
                payload.failedFiles.length;
            const allComplete =
                newTotalResolvedUploads ===
                Object.values(currentSession.files).length;

            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            ...failedFiles
                        },
                        sessionHasErrors: true,
                        totalResolvedUploads: newTotalResolvedUploads,
                        allComplete
                    }
                }
            };
        },
        addUploadToPendingBroadcasts: (
            state,
            { payload }: PayloadAction<{ sessionId: string; fileId: string }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            const file = currentSession.files[payload.fileId];

            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        pendingBroadcasts: {
                            ...currentSession.pendingBroadcasts,
                            [payload.fileId]: {
                                file
                            }
                        }
                    }
                }
            };
        },
        removeUploadsFromPendingBroadcasts: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; files: UploadedFile[] }>
        ) => {
            const removedUploads = payload.files?.reduce(
                (memo, upload) => ({
                    ...memo,
                    [upload.fileId]: undefined
                }),
                {}
            );
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        pendingBroadcasts: {
                            ...currentSession.pendingBroadcasts,
                            ...removedUploads
                        }
                    }
                }
            };
        },
        setIsErrored: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; isErrored: boolean }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        sessionHasErrors: payload.isErrored
                    }
                }
            };
        },
        addFiles: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; files: UploadedFile[] }>
        ) => {
            const addedFiles = payload.files?.reduce(
                (memo, currentFile) => ({
                    ...memo,
                    [currentFile?.file?.id]: {
                        fileId: currentFile.file?.id,
                        isBroadcastCreated: false,
                        addToPlayers: [],
                        isErrored: false,
                        isUploading: false,
                        isAwaitingUpload: true,
                        error: null,
                        ...currentFile
                    }
                }),
                {} as FileMap
            );
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            ...addedFiles
                        },
                        filesAdded: true
                    }
                }
            };
        },
        setTitle: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                title: string;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                title: payload.title
                            }
                        }
                    }
                }
            };
        },
        setDescription: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                description: string;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                description: payload.description
                            }
                        }
                    }
                }
            };
        },
        setThumbnailImageURL: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                thumbnailImageURL: string;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                thumbnailImageURL: payload.thumbnailImageURL
                            }
                        }
                    }
                }
            };
        },
        setThumbnailFile: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                thumbnailFile: File;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                thumbnailFile: payload.thumbnailFile
                            }
                        }
                    }
                }
            };
        },
        setIsBroadcastCreated: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                isBroadcastCreated: boolean;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                isBroadcastCreated: payload.isBroadcastCreated
                            }
                        }
                    }
                }
            };
        },
        setStreamMediaId: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                streamMediaId: string;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                streamMediaId: payload.streamMediaId
                            }
                        }
                    }
                }
            };
        },
        setAddToPlayers: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                addToPlayers: string[];
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                addToPlayers: payload.addToPlayers
                            }
                        }
                    }
                }
            };
        },
        setSelectedProduct: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                selectedProduct: CreatorProduct;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                selectedProduct: payload.selectedProduct
                            }
                        }
                    }
                }
            };
        },
        setCategories: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; categories: Category[] }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        categories: payload.categories
                    }
                }
            };
        },
        setWebLinks: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                webLinks: BroadcastWebLink[];
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        webLinks: payload.webLinks
                    }
                }
            };
        },
        setOnUploadComplete: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; onUploadComplete: () => any }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        onUploadComplete: payload.onUploadComplete
                    }
                }
            };
        },
        setAllowAdditionalUploads: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                allowAdditionalUploads: boolean;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        allowAdditionalUploads: payload.allowAdditionalUploads
                    }
                }
            };
        },
        resetVideoUploadSession: (
            state,
            { payload }: PayloadAction<{ sessionId: string }>
        ) => {
            const { [payload.sessionId]: _, ...restOfSessions } =
                state.uploadSessions;
            return {
                ...state,
                uploadSessions: {
                    ...restOfSessions
                }
            };
        },
        setUploadCanceled: (
            state,
            { payload }: PayloadAction<{ sessionId: string; fileId: string }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            const currentFile = currentSession.files[payload.fileId];
            const newTotalResolvedUploads =
                currentSession.totalResolvedUploads + 1;
            const allComplete =
                newTotalResolvedUploads ===
                Object.values(currentSession.files).length;

            currentFile.uppyInstance.clearUploadedFiles();

            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                isErrored: false,
                                isSuccess: false,
                                isUploading: false,
                                isCanceled: true
                            }
                        },
                        totalResolvedUploads: newTotalResolvedUploads,
                        allComplete
                    }
                }
            };
        },
        setOnFileAdded: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; onFileAdded: () => any }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        onFileAdded: payload.onFileAdded
                    }
                }
            };
        },
        setLockedToPlayer: (
            state,
            {
                payload
            }: PayloadAction<{ sessionId: string; lockedToPlayer: string }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        lockedToPlayer: payload.lockedToPlayer
                    }
                }
            };
        },
        setBroadcastStatus: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                broadcastStatus: BroadcastStatus;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                broadcastStatus: payload.broadcastStatus
                            }
                        }
                    }
                }
            };
        },
        setStartsAt: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                startsAt: string;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                startsAt: payload.startsAt
                            }
                        }
                    }
                }
            };
        },
        setShowInCatalog: (
            state,
            {
                payload
            }: PayloadAction<{
                sessionId: string;
                fileId: string;
                showInCatalog: boolean;
            }>
        ) => {
            const currentSession = getCurrentSession(state, payload);
            return {
                ...state,
                uploadSessions: {
                    ...state.uploadSessions,
                    [payload.sessionId]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            [payload.fileId]: {
                                ...currentSession.files[payload.fileId],
                                showInCatalog: payload.showInCatalog
                            }
                        }
                    }
                }
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            setUploadingFiles.fulfilled,
            (state, { payload: { currentSession, updatedFiles } }) => {
                state.uploadSessions = {
                    ...state.uploadSessions,
                    [currentSession.id]: {
                        ...currentSession,
                        files: {
                            ...currentSession.files,
                            ...updatedFiles
                        },
                        uploadsStarted: true
                    }
                };
            }
        );
        builder.addCase(cancelUploadSessions.fulfilled, (state) => {
            state.uploadSessions = {};
        });
    }
});

export const {
    setUppyInstances,
    setSuccessfulUploads,
    setFailedUploads,
    addFiles,
    setIsBroadcastCreated,
    setStreamMediaId,
    setAddToPlayers,
    setSelectedProduct,
    setCategories,
    setWebLinks,
    setOnUploadComplete,
    setAllowAdditionalUploads,
    resetVideoUploadSession,
    setTitle,
    setDescription,
    setThumbnailFile,
    setThumbnailImageURL,
    addUploadToPendingBroadcasts,
    removeUploadsFromPendingBroadcasts,
    setIsErrored,
    setUploadCanceled,
    setOnFileAdded,
    setLockedToPlayer,
    setBroadcastStatus,
    setShowInCatalog,
    setStartsAt
} = videoUploadSession.actions;

export default videoUploadSession.reducer;
