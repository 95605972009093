import { DownloadStep } from "./DownloadStep";
import { SelectPlanStep } from "./SelectPlanStep";
import { CreateAccountStep } from "./CreateAccountStep";
import { UploadVideoStep } from "./UploadVideoStep";
import { HubSpotSurveyStep } from "./HubSpotSurveyStep";
import { useTranslation } from "react-i18next";
const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

const SubscribeFlow = ({
    couponHook,
    stepsHook,
    onSelectPlanButtonClicked,
    selectedPlan,
    clientId,
    redirectStatus,
    defaultToSubscribeFlow,
    isTrialing,
    tag,
    isGrowUser
}) => {
    const { t } = useTranslation("getting-started");

    const {
        resellerInventoryItem,
        handleCouponCode,
        removeCoupon,
        showCouponInput
    } = couponHook;

    const { step, nextStep } = stepsHook;

    switch (step) {
        case 1:
            return (
                <SelectPlanStep
                    resellerInventoryItem={resellerInventoryItem}
                    handleCouponCode={handleCouponCode}
                    removeCoupon={removeCoupon}
                    showCouponInput={showCouponInput}
                    onSelectPlanButtonClicked={onSelectPlanButtonClicked}
                />
            );
        case 2:
            if (!selectedPlan) return <></>;
            return (
                <CreateAccountStep
                    nextStep={nextStep}
                    clientId={clientId}
                    redirectStatus={redirectStatus}
                    defaultToSubscribeFlow={defaultToSubscribeFlow}
                    resellerInventoryItem={resellerInventoryItem}
                    isTrialing={isTrialing}
                    tag={tag}
                    selectedPlan={selectedPlan}
                    handleCouponCode={handleCouponCode}
                    removeCoupon={removeCoupon}
                    showCouponInput={showCouponInput}
                />
            );
        case 3:
            if (isGrowUser === undefined) return <></>;
            return isGrowUser ? (
                <HubSpotSurveyStep
                    nextStep={nextStep}
                    hubSpotFormId="65ba3400-0214-4a82-925b-f04faaa7c13e"
                    headline={t("getting-started:survey-step:launch-title1")}
                    headlineBold={t(
                        "getting-started:survey-step:launch-title2"
                    )}
                />
            ) : (
                <HubSpotSurveyStep
                    nextStep={nextStep}
                    hubSpotFormId="8f4d7323-279e-4eae-8431-d867374f32ab"
                    headline={t("getting-started:survey-step:launch-title1")}
                    headlineBold={t(
                        "getting-started:survey-step:launch-title2"
                    )}
                />
            );
        case 4:
            if (isGrowUser === undefined) return <></>;
            return isGrowUser ? <UploadVideoStep /> : <DownloadStep />;
        case 5:
            if (skipDownloadStep) return <></>;
            return <DownloadStep />;
        default:
            return <></>;
    }
};

export default SubscribeFlow;
